import {
  XForm as RawXForm,
  rules,
  useToggle,
  DropDownForChild as RawDropDownForChild,
  ArrowOverlay as RawArrowOverlay,
  TooltipForChild,
  RadioGroupWithLabel,
  WithLabel as RawWithLabel,
  Document,
} from "@cdx/common";
import useMutation from "../../lib/hooks/useMutation";
import {
  Box,
  Col,
  css,
  DSIconBook,
  DSIconButton,
  DSIconCard,
  DSIconDeck,
  DSIconGdd,
  DSIconJourney,
  DSIconMisc,
  DSIconPencil,
  DSIconPlus,
  DSIconQa,
  DSIconRobot,
  DSIconSort,
  DSIconTasks,
  DSIconTrash,
  Row,
} from "@cdx/ds";
import {useModalWithData as RawUseModalWithData} from "../../components/Modals";
import {ComponentProps, forwardRef, lazy, ReactNode, useState} from "react";
import FancySelect from "../../components/FancySelect";
import {pluralize, sortedProjectDecks} from "../../lib/utils";
import {groupByFn} from "../../lib/collection-utils";
import {api} from "../../lib/api";
import useFeatureFlag, {FEATURE_FLAGS} from "../../components/useFeatureFlag";
import {useRoot} from "../../lib/mate/mate-utils";

const XForm = RawXForm as any;
const WithLabel = RawWithLabel as any;
const DropDownForChild = RawDropDownForChild as any;
const ArrowOverlay = RawArrowOverlay as any;
const useModalWithData = RawUseModalWithData as any;

const iconMap = {
  default: DSIconMisc,
  journey: DSIconJourney,
  robot: DSIconRobot,
  gdd: DSIconGdd,
  tasks: DSIconTasks,
  knowledge: DSIconBook,
  qa: DSIconQa,
};

type SpaceIconProps = {space: Omit<ProjectSpace, "defaultAllowedCardTypes">} & ComponentProps<
  typeof DSIconRobot
>;
export const SpaceIcon = ({space, ...rest}: SpaceIconProps) => {
  const Icon =
    (space.icon && iconMap[space.icon as "robot"]) ||
    (space.name && iconMap[space.name.toLowerCase() as "robot"]) ||
    DSIconMisc;
  return <Icon {...rest} />;
};

const iconMapEntries = Object.entries(iconMap);

type IconPickerProps = {
  onChange: (icon: string) => void;
  value: string | null;
};
const IconPicker = forwardRef<any, IconPickerProps>((props, ref) => {
  const {onChange, value} = props;
  return (
    <FancySelect
      getOptions={() => iconMapEntries}
      extractKey={([name]: any) => name}
      instanceToValue={([name]: any) => name}
      getSearchText={([name]: any) => name}
      onChange={onChange}
      value={value}
      ref={ref}
      placeholder="Icon"
      style={{width: 80}}
    >
      {([name, Comp]: any) => (
        <Col align="center" flex="auto">
          <Comp size={16} />
        </Col>
      )}
    </FancySelect>
  );
});

export const IconPickerWithLabel = forwardRef<any, any>(
  ({showErrors, name, label, hint, hasPendingValidation, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <IconPicker {...rest} ref={ref} />
    </WithLabel>
  )
);

export type ProjectSpace = {
  id: number;
  name: string;
  icon: string | null;
  defaultAllowedCardTypes: ("task" | "hero" | "doc")[];
};

type Project = {
  spaces: ProjectSpace[];
  id: string;
};

type Option = {
  label: string;
  description: ReactNode;
  defaultAllowedCardTypes: ProjectSpace["defaultAllowedCardTypes"];
};

const prefilledOptions: Option[] = [
  {
    label: "GDD",
    description: (
      <Document>
        For decks containing{" "}
        <a href="https://manual.codecks.io/hero-cards/" target="_blank" rel="noreferrer">
          Hero Cards
        </a>
      </Document>
    ),
    defaultAllowedCardTypes: ["hero"],
  },
  {
    label: "Tasks",
    description: "For decks representing work areas",
    defaultAllowedCardTypes: ["task"],
  },
  {
    label: "Knowledge",
    defaultAllowedCardTypes: ["doc"],
    description: (
      <Document>
        For decks containing{" "}
        <a href="https://manual.codecks.io/doc-cards/" target="_blank" rel="noreferrer">
          Doc Cards
        </a>
      </Document>
    ),
  },
  {
    label: "QA",
    description: "For decks containing bugs and issues",
    defaultAllowedCardTypes: ["task"],
  },
];

const formRules = (vals: any) => ({
  nameOption: [rules.isRequired],
  customName: vals.nameOption === "custom" ? [rules.isRequired] : [],
  customIcon: vals.nameOption === "custom" ? [rules.isRequired] : [],
});

const allAllowedTypes: ProjectSpace["defaultAllowedCardTypes"] = ["task", "hero", "doc"];

type FormProps = {
  initialName: string | null;
  initialIcon: string | null;
  onSubmit: (opts: {
    name: string;
    icon: string | null;
    defaultAllowedCardTypes: ProjectSpace["defaultAllowedCardTypes"];
  }) => unknown;
  buttonLabel: string;
  className?: string;
};
const Form = ({initialName, initialIcon, buttonLabel, onSubmit, className}: FormProps) => {
  const [state, setState] = useState<{
    customName: string;
    customIcon: string | null;
    nameOption: string;
  }>(() => {
    const isPrefilled = prefilledOptions.some((o) => initialName === o.label);
    if (isPrefilled) {
      return {customName: "", customIcon: null, nameOption: initialName!};
    } else {
      return {
        customName: initialName || "",
        customIcon: initialIcon,
        nameOption: "custom",
      };
    }
  });

  const root = useRoot();
  const withDeckTypes = useFeatureFlag(root.account, FEATURE_FLAGS.deckTypes);

  const handleSubmit = () => {
    const getAllowedCardTypes = () => {
      if (!withDeckTypes) return allAllowedTypes;
      if (state.nameOption === "custom") return allAllowedTypes;
      const prefilled = prefilledOptions.find((o) => o.label === state.nameOption);
      return prefilled?.defaultAllowedCardTypes ?? allAllowedTypes;
    };
    return onSubmit(
      state.nameOption === "custom"
        ? {
            name: state.customName,
            icon: state.customIcon,
            defaultAllowedCardTypes: getAllowedCardTypes(),
          }
        : {
            name: state.nameOption,
            icon: null,
            defaultAllowedCardTypes: getAllowedCardTypes(),
          }
    );
  };

  const handleChange = (next: any) => {
    const addingCustomName = state.customName < next.customName;
    setState({
      nameOption: addingCustomName ? "custom" : next.nameOption,
      customName: next.customName,
      customIcon: next.customIcon,
    });
  };

  return (
    <XForm
      buttonLabel={buttonLabel}
      buttonProps={{color: "purple"}}
      rules={formRules}
      values={state}
      onChange={handleChange}
      onSubmit={handleSubmit}
      className={className}
    >
      <XForm.Field
        as={RadioGroupWithLabel}
        name="nameOption"
        withDescription
        options={[
          ...prefilledOptions.map(({label, description}) => ({
            value: label,
            label: (
              <>
                <SpaceIcon
                  space={{name: label.toLowerCase(), icon: null, id: 0}}
                  inline
                  size={16}
                />{" "}
                {label}
              </>
            ),
            desc: description,
          })),
          {
            value: "custom",
            label: "Custom",
            postfixBelow: (
              <Row sp="12px">
                <XForm.Field as={IconPickerWithLabel} name="customIcon" label={null} />
                <XForm.Field name="customName" label={null} placeholder="Custom name" />
              </Row>
            ),
          },
        ]}
        label={null}
      />
    </XForm>
  );
};

type CreateSpaceOverlayProps = {
  overlayProps: unknown;
  close: () => void;
  project: Project;
};

export const CreateSpaceOverlay = ({overlayProps, close, project}: CreateSpaceOverlayProps) => {
  const [doUpdate] = useMutation("projects", "update");
  const handleSubmit = ({name, icon, defaultAllowedCardTypes}: any) => {
    const nextId = Math.max(...project.spaces.map((s) => s.id), 1) + 1;
    return doUpdate({
      id: project.id,
      spaces: [{id: nextId, name, icon, defaultAllowedCardTypes}, ...project.spaces],
    }).then(close);
  };
  return (
    <ArrowOverlay bg="white" arrowSize="xs" {...(overlayProps as any)}>
      <Col relative maxWidth="20rem" divideX>
        <Box bold size={16} color="primary" px="12px" py="12px">
          Create Space
        </Box>
        <Form
          initialName={prefilledOptions[0].label}
          initialIcon={null}
          onSubmit={handleSubmit}
          buttonLabel="Add"
          className={css({
            display: "flex",
            flexDir: "column",
            px: "12px",
            py: "12px",
            sp: "4px",
          })}
        />
      </Col>
    </ArrowOverlay>
  );
};

type CreateSpaceProps = {
  project: Project;
  className?: string;
};
export const CreateSpace = ({project, className}: CreateSpaceProps) => (
  <DropDownForChild
    setChildProps={({isOpen}: any) => ({tooltip: isOpen ? null : "Edit space", active: isOpen})}
    renderOverlay={({close, ...props}: any) => (
      <CreateSpaceOverlay overlayProps={props} project={project} close={close} />
    )}
    overlayProps={{placement: "right", distanceFromAnchor: 10}}
  >
    <TooltipForChild tooltip={null}>
      <DSIconButton
        icon={<DSIconPlus />}
        label="Add Space"
        variant="secondary"
        className={className}
      />
    </TooltipForChild>
  </DropDownForChild>
);

type EditSpaceOverlayProps = {
  overlayProps: unknown;
  close: () => void;
  project: Project;
  space: ProjectSpace;
  isEmpty: boolean;
};

export const EditSpaceOverlay = ({
  overlayProps,
  close,
  project,
  space,
  isEmpty,
}: EditSpaceOverlayProps) => {
  const [doUpdate] = useMutation("projects", "update");
  const handleSubmit = ({name, icon, defaultAllowedCardTypes}: any) => {
    const nextSpaces = project.spaces.map((s) =>
      s.id === space.id ? {...space, name, icon, defaultAllowedCardTypes} : s
    );
    return doUpdate({id: project.id, spaces: nextSpaces}).then(close);
  };
  const handleDelete = () => {
    const next = project.spaces.filter((s) => s.id !== space.id);
    return doUpdate({id: project.id, spaces: next}).then(close);
  };

  const isLastSpace = project.spaces.length < 2;
  return (
    <ArrowOverlay bg="white" arrowSize="xs" {...(overlayProps as any)}>
      <Col relative maxWidth="20rem" divideX>
        <Row px="12px" py="12px" align="center">
          <Box bold size={16} color="primary">
            Update Space
          </Box>
          <Box ml="auto">
            <TooltipForChild
              tooltip={
                !isEmpty
                  ? "Remove all decks before deleting this space"
                  : isLastSpace
                    ? "Can't delete the last space"
                    : "Remove space"
              }
              targetIsDisabled={!isEmpty || isLastSpace}
            >
              <DSIconButton
                icon={<DSIconTrash />}
                disabled={!isEmpty || isLastSpace}
                onClick={handleDelete}
                size="sm"
                negatePadding
              />
            </TooltipForChild>
          </Box>
        </Row>
        <Form
          buttonLabel="Update"
          initialIcon={space.icon || "default"}
          initialName={space.name || "Default Space"}
          onSubmit={handleSubmit}
          className={css({
            display: "flex",
            flexDir: "column",
            px: "12px",
            py: "12px",
            sp: "4px",
          })}
        />
      </Col>
    </ArrowOverlay>
  );
};

type EditSpaceLabelProps = {
  project: Project;
  space: ProjectSpace;
  isEmpty: boolean;
};

export const EditSpaceLabel = ({project, space, isEmpty}: EditSpaceLabelProps) => (
  <DropDownForChild
    setChildProps={({isOpen}: any) => ({tooltip: isOpen ? null : "Edit space", active: isOpen})}
    renderOverlay={({close, ...props}: any) => (
      <EditSpaceOverlay
        overlayProps={props}
        project={project}
        close={close}
        space={space}
        isEmpty={isEmpty}
      />
    )}
    overlayProps={{placement: "right", distanceFromAnchor: 10}}
  >
    <TooltipForChild tooltip={null}>
      <DSIconButton negatePadding variant="tertiary" icon={<DSIconPencil />} size="sm" />
    </TooltipForChild>
  </DropDownForChild>
);

const OrderSpacesOverlay = lazy(
  () => import(/* webpackChunkName: "OrderSpacesOverlay" */ "./OrderSpacesOverlay")
);

export const EditOrderButton = ({project}: {project: Project}) => {
  const [showManage, manageActions] = useToggle();
  const renderModal = useModalWithData(showManage, {
    onClose: manageActions.off,
    bg: "gray700",
    hideClose: true,
    width: 600,
  });

  const [doUpdate] = useMutation("projects", "update");
  const handleSaveOrder = (newSpaces: ProjectSpace[]) => {
    return doUpdate({id: project.id, spaces: newSpaces}).then(manageActions.off);
  };
  return (
    <>
      {renderModal(() => (
        <OrderSpacesOverlay
          onCancel={manageActions.off}
          onSave={handleSaveOrder}
          project={project}
        />
      ))}
      <TooltipForChild tooltip="Re-order Spaces">
        <DSIconButton
          onClick={(e) => {
            e.stopPropagation();
            manageActions.toggle();
          }}
          negatePadding
          variant="tertiary"
          icon={<DSIconSort />}
          size="sm"
        />
      </TooltipForChild>
    </>
  );
};

type SpaceAndDecks = {space: ProjectSpace; decks: any[]};
export const getDeckOrderForProject = ({
  project,
  dragInfo,
}: {
  project: Project;
  dragInfo?: any;
}): SpaceAndDecks[] => {
  const spacesAndDecks: SpaceAndDecks[] = [];
  const allDecks = sortedProjectDecks(project);
  const presentSpaceIds = new Set(project.spaces.map((s) => s.id));
  const defaultSpaceId = project.spaces.length > 0 ? project.spaces[0].id : 0;
  const bySpaceId = groupByFn(allDecks, (d: any) =>
    presentSpaceIds.has(d.spaceId) ? d.spaceId : defaultSpaceId
  );
  for (const space of project.spaces) {
    const spaceDecks: any[] = bySpaceId[space.id] || [];
    if (dragInfo) {
      if (dragInfo.sourceProjectId === project.id && dragInfo.sourceSpaceId === space.id) {
        const currIdx = spaceDecks.findIndex((d) => d.id === dragInfo.deckId);
        const removedDeck = spaceDecks.splice(currIdx, 1);
        // only insert it back in if it's still in the same space
        if (dragInfo.overProjectId === project.id && dragInfo.overSpaceId === space.id) {
          spaceDecks.splice(dragInfo.index, 0, ...removedDeck);
        }
      } else if (
        dragInfo.overProjectId === project.id &&
        dragInfo.overProjectId === project.id &&
        dragInfo.overSpaceId === space.id
      ) {
        const alreadyInHere = spaceDecks.some((d) => d.id === dragInfo.deckId);
        // might happen if the new order is fetched but the dragitem is still around
        if (!alreadyInHere) {
          const deck = api.getModel({modelName: "deck", id: dragInfo.deckId} as any);
          spaceDecks.splice(dragInfo.index, 0, deck);
        }
      }
    }
    spacesAndDecks.push({space, decks: spaceDecks});
  }
  return spacesAndDecks;
};

type DeckOrderWithSpacesArg = {
  projects: Project[];
  dragInfo?: any;
};
export const getDeckOrderWithSpaces = ({projects, dragInfo}: DeckOrderWithSpacesArg) => {
  const spacesAndDecksByProjectId: {[id: string]: SpaceAndDecks[]} = {};
  for (const project of projects) {
    spacesAndDecksByProjectId[project.id] = getDeckOrderForProject({project, dragInfo});
  }
  return spacesAndDecksByProjectId;
};

const getCardCountForDeck = (deck: any) => {
  const stats = deck.$meta.get("stats", {});
  const counts = stats.counts || {};
  return {
    incomplete:
      (counts.doc || 0) +
      (counts.blocked || 0) +
      (counts.review || 0) +
      (counts.hero || 0) +
      (counts.unassigned || 0) +
      (counts.assigned || 0) +
      (counts.snoozing || 0) +
      (counts.started || 0),
    complete: counts.done || 0,
  };
};

export const SpaceStats = ({decks}: {decks: any[]}) => {
  let complete = 0;
  let incomplete = 0;
  decks.forEach((deck) => {
    const sums = getCardCountForDeck(deck);
    complete += sums.complete;
    incomplete += sums.incomplete;
  });
  return (
    <Row sp="12px" align="center">
      <Row color="secondary" sp="4px">
        <DSIconDeck size={16} />
        <Box size={12}>{pluralize(decks.length, "Deck")}</Box>
      </Row>
      <Row color="secondary" sp="4px">
        <DSIconCard size={16} />
        <Box size={12}>
          {complete} / {pluralize(complete + incomplete, "Card")}
        </Box>
      </Row>
    </Row>
  );
};
