import {useState} from "react";
import {Link} from "react-router-dom";
import Markdown from "../../../components/Markdown";
import {filesize} from "filesize";
import FromNow, {useAgeColorProps} from "../../../components/FromNow";
import Uploader, {useUpload} from "../../../components/uploader";
import Avatar, {Robot} from "../../../components/Avatar";
import {by} from "../../../lib/sort";
import {XCol, XPush, XRow, XText} from "../../../components/xui";
import xcolors from "../../../components/xui/xcolors";
import FlipGroup from "react-flip-primitives";
import {getModalUrl, ModalAddress} from "../../../components/ModalRegistry";
import XTextButton from "../../../components/xui/XTextButton";
import {
  isMac,
  SubmitButton,
  XForm,
  useGlobalKeyPress,
  RawSpinner,
  WithTooltip,
  TooltipForChild,
  cx,
  DropDownForChild,
  ArrowOverlay,
} from "@cdx/common";
import useSet from "../../../lib/hooks/useSet";
import {useLocalStorageState} from "../../../lib/storage";
import {canDisplayAsImage} from "../../../lib/file-utils";
import {waitForResultPromise} from "../../../lib/wait-for-result";
import {api} from "../../../lib/api";
import {attachmentPanelStyles as styles} from "../unified-card-container.css";
import {CdxImgByFile, CdxImgForExternalUrl} from "../../../components/CdxImg";
import cdxEnv from "../../../env";
import {
  Col,
  css,
  DSButton,
  DSIconAttachment,
  DSIconButton,
  DSIconDoc,
  DSIconDownload,
  DSIconFilter,
  DSIconMedia,
  DSIconPencil,
  DSIconPlay,
  DSIconSortAsc,
  DSIconSortDesc,
  DSIconTrash,
  Row,
  Text,
} from "@cdx/ds";
import {
  CdxLexicalEditorWithLabel,
  useCdxEditorState,
} from "../../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import dsStyles from "@cdx/ds/css/index.css";
import CoverImageOptions from "./CoverImageOptions";
import {hasChildCards} from "../../workflows/workflow-utils";

export const MAX_ATTACHMENT_THUMB_WIDTH = 222;

const filterTypes = {
  images: {
    sortIndex: 1,
    icon: <DSIconMedia />,
    name: "images",
    tooltip: "Filter for images.",
  },
  documents: {
    sortIndex: 2,
    icon: <DSIconDoc />,
    name: "documents",
    tooltip: "Filter for documents.",
  },
  media: {
    sortIndex: 3,
    icon: <DSIconPlay />,
    name: "media",
    tooltip: "Filter for videos and audio files.",
  },
  other: {
    sortIndex: 4,
    icon: <DSIconAttachment />,
    name: "other",
    tooltip: "Filter for other types.",
  },
};

const FileArea = ({children}) => (
  <XCol bg="gray100" elevation={1} pa={2} align="center" sp={0} style={{wordBreak: "break-word"}}>
    {children}
  </XCol>
);

export const DisplayFile = ({card, attachment, file, isCreating}) => {
  if (file.isDeleted) {
    return (
      <FileArea>
        {file.meta.userReport ? (
          <XText size={2}>file wasn't uploaded</XText>
        ) : (
          <XRow align="baseline" sp={1}>
            <XText size={2}>file deleted by</XText>
            {file.deletedBy ? (
              <XRow align="baseline" sp={0}>
                <Avatar
                  user={file.deletedBy}
                  size={16}
                  style={{display: "inline-flex", top: 2, position: "relative"}}
                />

                <XText preset="bold" size={2}>
                  {" "}
                  {file.deletedBy.name}
                </XText>
              </XRow>
            ) : (
              <Robot size={16} />
            )}
          </XRow>
        )}
        {file.deletedAt && (
          <XText size={1} color="gray500">
            <FromNow date={file.deletedAt} />
          </XText>
        )}
      </FileArea>
    );
  } else {
    const url = file.$meta.get("url", null);
    const isImage = canDisplayAsImage(file);
    if (url && isImage) {
      return (
        <XCol align="center" justify="center">
          {isCreating ? (
            <XCol elevation={1} bg="white">
              <CdxImgForExternalUrl src={url} maxWidth={MAX_ATTACHMENT_THUMB_WIDTH} />
            </XCol>
          ) : card ? (
            <ModalAddress modal={`attachmentViewer.att-${card.id}.${attachment.id}`}>
              {(to) => (
                <XCol as={Link} to={to} elevation={1} bg="white">
                  <CdxImgByFile
                    file={file}
                    maxWidth={MAX_ATTACHMENT_THUMB_WIDTH}
                    alt={attachment.title}
                  />
                </XCol>
              )}
            </ModalAddress>
          ) : (
            <XCol elevation={1} bg="white">
              <CdxImgByFile
                file={file}
                maxWidth={MAX_ATTACHMENT_THUMB_WIDTH}
                alt={attachment.title}
              />
            </XCol>
          )}
        </XCol>
      );
    } else {
      const pending = file.meta.userReport?.performChecks;
      const icon = pending ? (
        <WithTooltip tooltip="Upload process pending" as={RawSpinner} size={24} />
      ) : (
        <DSIconAttachment size={20} style={{color: xcolors.gray500}} />
      );
      const props = pending
        ? {}
        : {as: "a", href: url, target: "_blank", rel: "noopener noreferrer"};
      return (
        <FileArea>
          <XRow {...props} sp={1} align="center">
            {icon}
            <XText size={2}>{file.$meta.get("name", "loading...")}</XText>
          </XRow>
        </FileArea>
      );
    }
  }
};

const SaveButton = ({formBag, isDone}) => (
  <XRow align="baseline" sp={0}>
    <XPush />
    <XText color="gray500" size={1}>
      Press <b>{isMac() ? "cmd" : "ctrl"}+enter</b> to
    </XText>
    <SubmitButton as={XTextButton} formBag={formBag} isDone={isDone} color="red">
      Update
    </SubmitButton>
  </XRow>
);

const formRules = {
  content: [[(val) => !val || val.isEmpty === false, "needs to have some content"]],
};

const EditDescription = ({attachment, onEdit, card}) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const contentField = useCdxEditorState({
    // during card creation attachment is a synthetic instance without `$meta`
    initialContent: () => attachment.$meta?.get("content", false) || attachment.content,
    contentKey: attachment.$meta?.get("id", null) || attachment.id,
    onChangeListeners: {
      isEmpty: setIsEmpty,
    },
  });

  const handleEditSubmit = () => onEdit({id: attachment.id, content: contentField.getText()});

  return (
    <XCol
      as={XForm}
      onSubmit={handleEditSubmit}
      values={{content: {...contentField.state, isEmpty}}}
      rules={formRules}
      buttonComp={SaveButton}
    >
      <XForm.Field
        as={CdxLexicalEditorWithLabel}
        label={null}
        name="content"
        placeholder="you can use Markdown here"
        maybeProject={card.deck && card.deck.project}
        submitOnCmdEnter
        autoFocus
      />
    </XCol>
  );
};

const Description = ({attachment, onEdit, canModify, card}) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <XCol sp={1}>
      <XRow>
        <XPush />
        <DSButton variant="tertiary" size="sm" onClick={() => setIsEditing(false)} negatePadding>
          Cancel
        </DSButton>
      </XRow>
      <EditDescription
        attachment={attachment}
        onEdit={(data) => onEdit(data).then(() => setIsEditing(false))}
        card={card}
      />
    </XCol>
  ) : (
    <XRow sp={2} align="start">
      <Markdown projectId={card.deck && card.deck.project.id} size="sm">
        {attachment.content}
      </Markdown>
      <XPush />
      {canModify && (
        <DSIconButton
          icon={<DSIconPencil />}
          onClick={() => setIsEditing(true)}
          variant="tertiary"
          size="sm"
          negatePadding
        />
      )}
    </XRow>
  );
};

const CoverImageButton = ({attachment, card, onSetCoverFile}) => {
  if (!attachment.file) return null;
  const isActive = card.coverFile && card.coverFile.id === attachment.file.id;
  if (!isActive && !canDisplayAsImage(attachment.file)) return null;

  return (
    <DropDownForChild
      setChildProps={({isOpen}) => ({hidden: isOpen})}
      renderOverlay={({close, ...props}) => (
        <ArrowOverlay bg="white" arrowSize="xs" allowMouseEventBubbling {...props}>
          <CoverImageOptions
            onClose={close}
            onSetCoverFile={onSetCoverFile}
            file={attachment.file}
            cardMeta={card.meta}
            isCurrent={isActive}
            withChildCards={hasChildCards(card)}
          />
        </ArrowOverlay>
      )}
      overlayProps={{placement: "right", distanceFromAnchor: 10}}
    >
      <TooltipForChild
        tooltip={
          onSetCoverFile
            ? "Set as Cover Image"
            : "Cover Image is derived from Hero Card Cover Image (See Organization Settings)."
        }
        targetIsDisabled={!onSetCoverFile}
      >
        <DSIconButton
          size="sm"
          variant="tertiary"
          negatePadding
          icon={<DSIconMedia />}
          active={isActive}
          disabled={!onSetCoverFile}
        />
      </TooltipForChild>
    </DropDownForChild>
  );
};

const getDownloadableUrl = (url) => {
  if (!cdxEnv.UPLOAD_HOST) return url;
  // turns https://uploads.codecks.io/my-link to `/$uploads/my-link`
  if (!url.startsWith(cdxEnv.UPLOAD_HOST)) return url;

  const parsedUrl = new URL(url);
  return `/$uploads${parsedUrl.pathname}`;
};

const Attachment = ({
  card,
  attachment,
  onDelete,
  canModify,
  registerFlipNode,
  isCreating,
  onEdit,
  onSetCoverFile,
}) => {
  const {file} = attachment;
  const createdAt = attachment.$meta ? attachment.$meta.get("createdAt") : attachment.createdAt;
  const ageProps = useAgeColorProps(createdAt);
  if (attachment.id === null) return <div>???</div>;
  return (
    <XCol
      sp={2}
      pl={3}
      relative
      ref={registerFlipNode(attachment.id.toString(), {
        onPresence: (val) => ({opacity: val}),
      })}
    >
      <XRow align="start" sp={1} relative>
        <Col className={cx(dsStyles.overflow.hidden)}>
          {attachment.creator ? (
            <>
              <Avatar
                user={attachment.creator}
                size={16}
                style={{position: "absolute", top: 0, left: "-1.4rem"}}
              />
              <XText
                preset="bold"
                size={2}
                className={cx(dsStyles.overflow.hidden, dsStyles.textOverflow.ellipsis)}
              >
                {attachment.creator.name}
              </XText>
            </>
          ) : (
            <Robot size={16} style={{position: "absolute", top: 0, left: "-1.4rem"}} />
          )}
          <XText size={0} {...ageProps} className={css({flex: "none"}, ageProps.className)}>
            <FromNow date={attachment.createdAt} />
          </XText>
        </Col>
        <XPush />
        <XRow sp={1} align="center" noShrink>
          {!file.meta.userReport?.performChecks && !file?.isDeleted && (
            <XText preset="label" size={0} color="gray500" className={dsStyles.flex.none}>
              {filesize(file.$meta.get("size", 0), {base: 2, standard: "jedec"})}
            </XText>
          )}
          <XRow sp={0} relative style={{height: 18}} align="center">
            {canModify && (
              <CoverImageButton
                attachment={attachment}
                card={card}
                onSetCoverFile={onSetCoverFile}
              />
            )}
            {!isCreating && attachment.file && !attachment.file.isDeleted && (
              <TooltipForChild tooltip="Download Attachment">
                <DSIconButton
                  icon={<DSIconDownload />}
                  variant="tertiary"
                  size="sm"
                  negatePadding
                  href={
                    process.env.REACT_APP_MODE === "open"
                      ? attachment.file.url
                      : getDownloadableUrl(attachment.file.url)
                  }
                  download={attachment.title}
                />
              </TooltipForChild>
            )}
            {canModify && (
              <XTextButton
                square
                onClick={() => onDelete(attachment.id)}
                tooltip="Remove Attachment"
                color="redOnHover"
              >
                <DSIconTrash size={16} />
              </XTextButton>
            )}
          </XRow>
        </XRow>
      </XRow>
      <DisplayFile file={file} card={card} attachment={attachment} isCreating={isCreating} />
      <Description attachment={attachment} onEdit={onEdit} canModify={canModify} card={card} />
    </XCol>
  );
};

const imageTypes = new Set([
  "bmp",
  "gif",
  "png",
  "tiff",
  "psd",
  "ico",
  "cur",
  "webp",
  "svg",
  "jpeg",
  "jpg",
]);

const fileToType = (file) => {
  if (file.meta && imageTypes.has(file.meta.type)) return "images";
  if (
    /\.(avi|webm|mp4|mk4|flv|vob|ogv|ogg|mov|qt|m[24]v|mpe?g|mp3|wav|asf|aif|mid|wma|flac|aac)$/i.test(
      file.name
    )
  )
    return "media";
  if (/\.(docx?|xlsx?|pdf|pages|pptx?|txt|rtf|keynote|dotx?)$/i.test(file.name)) return "documents";
  return "other";
};

const FilterButton = ({filter, isActive, onClick, count}) => (
  <TooltipForChild tooltip={filter.tooltip}>
    <DSIconButton
      icon={filter.icon}
      variant="secondary"
      size="sm"
      active={isActive}
      onClick={() => onClick(filter.name)}
      label={count}
    />
  </TooltipForChild>
);

const HeaderWithFilters = ({
  attachments,
  activeFilters,
  onFilterClick,
  clearFilters,
  order,
  setOrder,
  canModify,
  card,
  root,
  isCreating,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const numAtts = attachments.length;
  const typeCount = attachments
    .filter((a) => a.file)
    .reduce((m, a) => {
      const type = fileToType(a.file);
      m[type] = (m[type] || 0) + 1;
      return m;
    }, {});

  const handleToggle = () => {
    setShowFilters(!showFilters);
    clearFilters();
  };
  const isDesc = order === "desc";
  return (
    <XRow sp={4} align="center">
      {showFilters ? (
        <Row wrap sp="8px">
          {Object.keys(typeCount)
            .map((f) => filterTypes[f])
            .sort(by((f) => f.sortIndex))
            .map((f) => (
              <FilterButton
                key={f.name}
                filter={f}
                count={typeCount[f.name]}
                isActive={activeFilters.has(f.name)}
                onClick={onFilterClick}
              />
            ))}
        </Row>
      ) : (
        <XRow align="center" sp={2}>
          <Text type="label11Caps" color="secondary">
            {numAtts === 0
              ? canModify
                ? "Add attachments"
                : "No attachments"
              : `${numAtts} attached file${numAtts === 1 ? "" : "s"}`}
          </Text>
          {!isCreating && numAtts > 0 && (
            <TooltipForChild tooltip="Download all attachments">
              <DSIconButton
                icon={<DSIconDownload />}
                variant="secondary"
                size="sm"
                href={`${cdxEnv.API_HOST}/files/card-attachment-bundle?cardId=${card.cardId}&x-account=${root.account.subdomain}`}
              />
            </TooltipForChild>
          )}
        </XRow>
      )}
      {numAtts > 0 && (
        <Row ml="auto" align="center" sp="4px">
          <TooltipForChild tooltip="Filter attachments by type">
            <DSIconButton
              size="sm"
              variant="secondary"
              active={showFilters}
              onClick={handleToggle}
              icon={<DSIconFilter />}
            />
          </TooltipForChild>
          <TooltipForChild tooltip={isDesc ? "Showing oldest first" : "Showing newest first"}>
            <DSIconButton
              size="sm"
              variant="secondary"
              onClick={() => setOrder(isDesc ? "asc" : "desc")}
              icon={isDesc ? <DSIconSortDesc size={16} /> : <DSIconSortAsc size={16} />}
            />
          </TooltipForChild>
        </Row>
      )}
    </XRow>
  );
};

const getFilteredAttachments = ({card, activeFilters, order}) => {
  let filteredAttachments = card.attachments;
  if (activeFilters.size) {
    filteredAttachments = filteredAttachments.filter((a) => activeFilters.has(fileToType(a.file)));
  }
  if (order === "desc") {
    filteredAttachments = [...filteredAttachments].reverse();
  }
  return filteredAttachments;
};

const Attachments = ({
  card,
  onDelete,
  onUpload,
  onEdit,
  isCreating,
  root,
  onSetCoverFile,
  canModify,
  location,
  history,
}) => {
  const [activeFilters, {clear: clearFilters, toggle: toggleFilters}] = useSet();
  const [order, setOrder] = useLocalStorageState("default-attachment-order", "asc");

  const handleUpload = (file) => {
    if (activeFilters.size) clearFilters();
    return onUpload(file, card.id);
  };

  let filteredAttachments = getFilteredAttachments({card, activeFilters, order});

  useGlobalKeyPress({
    key: "a",
    disabled: isCreating,
    fn: () => {
      waitForResultPromise(() => {
        const freshCard = api.getModel({modelName: "card", id: card.id});
        return {
          firstImage: getFilteredAttachments({card: freshCard, activeFilters, order}).find(
            (a) => a.file.url && canDisplayAsImage(a.file)
          ),
          cardId: card.id,
        };
      }).then(({firstImage, cardId}) => {
        if (!firstImage) return;
        const url = getModalUrl({
          modal: `attachmentViewer.att-${cardId}.${firstImage.id}`,
          location,
        });
        history.push(url);
      });
    },
  });

  const {inputProps, ongoingUploads} = useUpload({
    id: `attachment-${card.id}`,
    multiple: true,
    affectsQuota: true,
    onUpload: handleUpload,
  });

  return (
    <Col pl="16px" pr="12px" pt="12px" pb="32px" data-cdx-context="Attachments">
      <HeaderWithFilters
        card={card}
        attachments={card.attachments}
        onFilterClick={(name) => toggleFilters(name)}
        clearFilters={clearFilters}
        activeFilters={activeFilters}
        order={order}
        setOrder={setOrder}
        canModify={canModify}
        root={root}
        isCreating={isCreating}
      />
      {canModify && (
        <div className={styles.uploadArea}>
          <Uploader.Input inputProps={inputProps}>Drop attachment</Uploader.Input>
        </div>
      )}
      <XCol sp={2}>
        <Uploader.Uploads ongoingUploads={ongoingUploads} pb={3} />
      </XCol>
      <FlipGroup
        changeKey={filteredAttachments.map((a) => a.id)}
        keysAndData={filteredAttachments.map((a) => ({
          key: a.id.toString(),
          data: a,
        }))}
      >
        {(registerFlipNode, keysAndData) => (
          <XCol sp={5}>
            {keysAndData
              .map(({key, data}) => (
                <Attachment
                  attachment={data}
                  card={card}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  key={key}
                  canModify={canModify}
                  registerFlipNode={registerFlipNode}
                  isCreating={isCreating}
                  onSetCoverFile={onSetCoverFile}
                />
              ))
              .reverse()}
          </XCol>
        )}
      </FlipGroup>
    </Col>
  );
};

export default Attachments;
