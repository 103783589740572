import {Card, CardId} from "../../cdx-models/Card";
import {Root} from "../../cdx-models/Root";
import {TimeTrackingSegment} from "../../cdx-models/TimeTrackingSegment";
import {TimeTrackingSum} from "../../cdx-models/TimeTrackingSum";
import {User, UserId} from "../../cdx-models/User";
import {getChildCards} from "../workflows/workflow-utils";

export const getSegmentDuration = (seg: TimeTrackingSegment) =>
  (seg.finishedAt || new Date()).getTime() - seg.startedAt.getTime() + seg.modifyDurationMsBy;

export const timeByUser = (segments: TimeTrackingSegment[]) => {
  const byUser: Record<UserId, {user: User; msCount: number; isRunning: boolean}> = {};
  segments.forEach((seg) => {
    const {user, startedAt, finishedAt, modifyDurationMsBy} = seg;
    const userId = user?.$meta.get("id", null);
    if (!userId) return;
    const msSum = (finishedAt || new Date()).getTime() - startedAt.getTime() + modifyDurationMsBy;
    const exist = byUser[userId];
    if (exist) {
      exist.msCount += msSum;
      if (!finishedAt) exist.isRunning = true;
    } else {
      byUser[userId] = {user, msCount: msSum, isRunning: !finishedAt};
    }
  });
  return byUser;
};

export const timeTrackingInfo = (cards: Card[]) => {
  const cardMap = new Map<CardId, Card>();
  cards.forEach((c) => {
    cardMap.set(c.cardId as CardId, c);
    getChildCards(c).forEach((child) => {
      cardMap.set(child.cardId as CardId, child);
    });
  });
  const allCards = [...cardMap.values()];
  return {
    totalTime: allCards.reduce(
      (s, c) => s + getTotalTimeForTimeTrackingSum(c.totalTimeTrackingSums),
      0
    ),
    getTimeByUser: () => {
      const allTimes: TimeTrackingSum[] = [];
      allCards.forEach((card) =>
        allTimes.push(...card.$meta.find("userTimeTrackingSums", {$order: "-sumMs"}))
      );
      const byUser: Record<UserId, {user: User; time: number; isRunning: boolean}> = {};
      allTimes.forEach((ut) => {
        const key = ut.user.id as UserId;
        const time = getTotalTimeForTimeTrackingSum(ut);
        const exist = byUser[key];
        if (exist) {
          exist.time += time;
          if (ut.runningStartedAt) exist.isRunning = true;
        } else {
          byUser[key] = {user: ut.user, time, isRunning: Boolean(ut.runningStartedAt)};
        }
      });
      return byUser;
    },
  };
};

export const getTotalTimeForTimeTrackingSum = (ttSum: TimeTrackingSum) => {
  if (!ttSum) return 0;
  const {sumMs, runningModifyDurationMsBy} = ttSum;
  const runningStartedAt = ttSum.$meta.get("runningStartedAt", null);
  const currTime = runningStartedAt
    ? new Date().getTime() - runningStartedAt.getTime() + runningModifyDurationMsBy
    : 0;
  return sumMs + currTime;
};

export const getTotalTimeForCard = (card: Card) => {
  const myTime = getTotalTimeForTimeTrackingSum(card.totalTimeTrackingSums);
  const childCards = getChildCards(card);
  return childCards.reduce(
    (sum, child) => sum + getTotalTimeForTimeTrackingSum(child.totalTimeTrackingSums),
    myTime
  );
};

export const getCurrentTimeTrackingSegment = (root: Root) => {
  const meId = root.loggedInUser?.$meta.get("id", null);
  if (!meId) return null;
  const segs = root.account.$meta.find("timeTrackingSegments", {
    userId: meId,
    finishedAt: null,
  });
  return segs[0] ?? null;
};

export const getCurrentTimeTrackingSegmentsForCard = (cardId: CardId | null, root: Root) => {
  if (!cardId) return [];
  return root.account.$meta.find("timeTrackingSegments", {
    cardId: cardId,
    finishedAt: null,
    $order: "createdAt",
  });
};
