import {Box, Col, Row, Text} from "@cdx/ds";
import {AvatarAction} from "./get-convo-actions";
import {TooltipForChild, cx} from "@cdx/common";
import {orderBy} from "lodash";
import {DSAvatarStyles} from "../../../../components/DSAvatar/DSAvatar.css";
import {ResolvableParticipant} from "../../../../cdx-models/ResolvableParticipant";
import {convoStyles} from "./convo.css";
import {ConvoAvatar, ResolvableBag} from "./convo-utils";
import {User} from "../../../../cdx-models/User";
import Emoji from "../../../../lib/emoji/EmojiRenderer";

type ConvoParticipantInfo = {
  user: User | null;
  done: boolean;
  score: number;
  joined: Date;
  isMe: boolean;
  status: ResolvableParticipant["status"];
  reaction: string | null;
};

const ConvoParticipantsTooltip = ({
  participants,
  resBag,
}: {
  participants: ConvoParticipantInfo[];
  resBag: ResolvableBag;
}) => {
  const getStatusLabel = (rp: ConvoParticipantInfo) => {
    switch (rp.status) {
      case "active":
        return "active";
      case "opt_out":
        return "opted out";
      case "approve":
        if (rp.reaction) return <Emoji block>{rp.reaction}</Emoji>;
        return "approved";
      default:
        return null;
    }
  };
  return (
    <Col pa="12px" sp="8px" bg="foreground" colorTheme="gray550">
      {participants.map((p, idx) => {
        return (
          <Row key={p.user?.$meta.get("id", idx) || idx} align="center" sp="4px">
            <ConvoAvatar
              size={24}
              user={p.user}
              className={p.done ? convoStyles.avatar.done : undefined}
              resBag={resBag}
            />
            <Text type="label12" color={p.done ? "secondary" : "primary"}>
              {p.user?.name}
            </Text>
            {p.done && (
              <Row ml="auto" sp="8px" pl="12px">
                <Text type="label12light" color="secondary">
                  {getStatusLabel(p)}
                </Text>
              </Row>
            )}
          </Row>
        );
      })}
    </Col>
  );
};

type ConvoAvatarsProps = {
  resBag: ResolvableBag;
  avatarAction: AvatarAction | null;
  className?: string;
};

const ConvoParticipants = ({resBag, avatarAction, className}: ConvoAvatarsProps) => {
  const {root, resolvable} = resBag;
  const meId = root.loggedInUser?.$meta.get("id", null);
  const getParticipantInfo = (p: ResolvableParticipant): ConvoParticipantInfo => {
    const isMe = Boolean(meId && p.user?.id === meId);
    const getScore = () => {
      if (isMe) return 10;
      if (!p.done) return 1;
      return 0;
    };
    return {
      user: p.user,
      done: p.done,
      score: getScore(),
      joined: p.firstJoinedAt,
      status: p.status,
      reaction: (p.done && p.reaction) || null,
      isMe,
    };
  };

  const list = resolvable.participants.map(getParticipantInfo);

  const ordered = orderBy(list, ["score", "joined"], ["desc", "asc"]);
  const maxCount = 6;
  const tooMuch = ordered.length > maxCount;
  const shownList = [...(tooMuch ? ordered.slice(0, maxCount - 1) : ordered)].reverse();
  const withInteractiveMe = ordered[0]?.isMe && avatarAction?.onClick;
  const rest = withInteractiveMe ? shownList.slice(0, shownList.length - 1) : shownList;

  return (
    <Box display="flex" flexDir="row-reverse" align="center" className={className}>
      <TooltipForChild
        tooltip={<ConvoParticipantsTooltip participants={ordered} resBag={resBag} />}
        bg="gray700"
      >
        <Box
          display="flex"
          flexDir="row-reverse"
          align="center"
          className={cx(DSAvatarStyles.stacked, DSAvatarStyles.sizes[24])}
        >
          {tooMuch && (
            <div className={cx(DSAvatarStyles.sizes[24], DSAvatarStyles.tooMuch)}>
              +{ordered.length - shownList.length}
            </div>
          )}
          {rest.map((p, idx) => (
            <ConvoAvatar
              key={p.user?.$meta.get("id", idx) || idx}
              resBag={resBag}
              size={24}
              className={cx(DSAvatarStyles.stacked, p.done && convoStyles.avatar.done)}
              user={p.user}
            />
          ))}
        </Box>
      </TooltipForChild>
      {withInteractiveMe && (
        <TooltipForChild tooltip={avatarAction?.tooltip}>
          <ConvoAvatar
            resBag={resBag}
            size={24}
            className={cx(
              DSAvatarStyles.stacked,
              ordered[0].done && convoStyles.avatar.done,
              avatarAction?.onClick && convoStyles.avatar.clickable,
              ordered[0].done && avatarAction?.onClick && convoStyles.avatar.doneAndClickable
            )}
            onClick={avatarAction?.onClick || undefined}
            user={ordered[0].user}
          />
        </TooltipForChild>
      )}
    </Box>
  );
};

export default ConvoParticipants;
