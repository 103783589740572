import {useMemo} from "react";
import {cardDiffToTypes, NotificationType, notificationTypesToUi} from "./card-diff-info";
import {api} from "../../lib/api";
import {Notifications} from "./useNotifications";
import {UserId} from "../../cdx-models/User";
import {Card, CardId} from "../../cdx-models/Card";
import {ResolvableNotification} from "../../cdx-models/ResolvableNotification";
import {CardDiffNotification} from "../../cdx-models/CardDiffNotification";

const cmpTypes = (t1: NotificationType, t2: NotificationType) =>
  (notificationTypesToUi[t2].priority || 1) - (notificationTypesToUi[t1].priority || 1);

const cmpByDate = (n1: {date: Date}, n2: {date: Date}) => (n1.date < n2.date ? 1 : -1);

const createDiffGrouper = <T>(
  acceptTypes: (types: Set<NotificationType>) => boolean,
  collectBy: (n: CardDiffNotification) => T,
  groupProps: {
    type: GroupType;
    toVal: (val: NonNullable<T>) => Record<string, any>;
  }
) => {
  const map = new Map<T, {n: CardDiffNotification; types: Set<NotificationType>}[]>();
  return {
    add: (n: CardDiffNotification, types: Set<NotificationType>) => {
      if (!acceptTypes(types)) return false;
      const key = collectBy(n);
      const exist = map.get(key);
      if (exist) {
        exist.push({n, types});
      } else {
        map.set(key, [{n, types}]);
      }
      return true;
    },
    compile: () => {
      const results: CardDiffStackNotification[] = [];
      for (const [key, list] of map.entries()) {
        if (list.length <= 2 || !key) {
          for (const {n, types} of list) {
            results.push({
              type: "cardDiff",
              notification: n,
              types: [...types],
              key: `cardDiff-${n.card.$meta.get("cardId", `none-${key}`)}}`,
              date: n.createdAt,
            });
          }
        } else {
          const changerSet = new Set(list.flatMap(({n}) => n.changers));
          results.push({
            type: groupProps.type,
            notificationCount: list.length,
            ...groupProps.toVal(key),
            changers: [...changerSet],
            key: `${groupProps.type}-${key}`,
            date: list[0].n.createdAt,
            cardIds: list.map(({n}) => n.card.$meta.get("cardId", null)!).filter(Boolean),
          });
        }
      }
      return results;
    },
  };
};

const groupDiffsNext = (
  diffs: CardDiffNotification[],
  userId: UserId,
  collectByDeck?: boolean
): {list: CardDiffStackNotification[]; notisWithoutType: CardDiffNotification[]} => {
  const list: CardDiffStackNotification[] = [];
  const notisWithoutType: CardDiffNotification[] = [];
  const groupers = [
    createDiffGrouper(
      (t) => t.size === 1 && t.has("movedDeck"),
      (n) => n.changes.deckId?.[1],
      {
        type: "targetDeck",
        toVal: (deckId) => ({deck: api.getModel({modelName: "deck", id: deckId})}),
      }
    ),
    createDiffGrouper(
      (t) => t.size === 1 && t.has("milestone"),
      (n) => n.changes.milestoneId?.[1],
      {
        type: "targetMilestone",
        toVal: (msId) => ({milestone: api.getModel({modelName: "milestone", id: msId})}),
      }
    ),
    createDiffGrouper(
      (t) => t.size === 1 && t.has("sprint"),
      (n) => n.changes.sprintId?.[1],
      {
        type: "targetSprint",
        toVal: (sprintId) => ({sprint: api.getModel({modelName: "sprint", id: sprintId})}),
      }
    ),
  ];

  if (collectByDeck) {
    groupers.push(
      createDiffGrouper(
        () => true,
        (n) => n.card.deck?.$meta.get("id", null),
        {
          type: "subbedDeck",
          toVal: (deckId) => ({deck: api.getModel({modelName: "deck", id: deckId})}),
        }
      )
    );
  }

  let idx = 0;
  for (const n of diffs) {
    idx += 1;
    const changes = n.$meta.get("changes", null);
    if (!changes) continue;
    const types = cardDiffToTypes(changes, userId);
    if (types.size === 0) {
      notisWithoutType.push(n);
      continue;
    }
    if (groupers.some((g) => g.add(n, types))) continue;
    list.push({
      type: "cardDiff",
      notification: n,
      types: [...types].sort(cmpTypes),
      key: `cardDiff-${n.card.$meta.get("cardId", `none-${idx}`)}}`,
      date: n.createdAt,
    });
  }
  groupers.forEach((g) => list.push(...g.compile()));

  return {list, notisWithoutType};
};

export type DueStackNotification = {
  type: "dueCard";
  card: Card;
  key: string;
  date: Date;
};

export type AutoFinishedTimeTrackingSegmentStackNotification = {
  type: "autoFinishedTimeTrackingSegment";
  card: Card;
  key: string;
  date: Date;
};

export type ConvStackNotification = {
  type: "res";
  notification: ResolvableNotification;
  key: string;
  date: Date;
};

type GroupType = "targetDeck" | "targetMilestone" | "targetSprint" | "subbedDeck";

export type CardDiffStackNotification = {
  key: string;
  date: Date;
} & (
  | {type: "cardDiff"; notification: CardDiffNotification; types: NotificationType[]}
  | {
      type: GroupType;
      changers: UserId[];
      cardIds: CardId[];
      notificationCount: number;
    }
);

export type NotificationStacks = {
  due: DueStackNotification[];
  autoFinishedTimeTrackingSegments: AutoFinishedTimeTrackingSegmentStackNotification[];
  conv: ConvStackNotification[];
  owner: CardDiffStackNotification[];
  subbed: (CardDiffStackNotification | ConvStackNotification)[];
};

const useNotificationStacks = ({
  notifications,
  userId,
}: {
  notifications: Notifications;
  userId: UserId;
}) => {
  return useMemo(() => {
    const {diffs, resolvables, dueCards, autoFinishedTimeTrackingSegments} = notifications;
    const ownerInfo = groupDiffsNext(
      diffs.filter((n) => n.asOwner),
      userId
    );
    const subInfo = groupDiffsNext(
      diffs.filter((n) => !n.asOwner),
      userId,
      true
    );
    const notisWithoutType = [...ownerInfo.notisWithoutType, ...subInfo.notisWithoutType];

    const stacks: NotificationStacks = {
      autoFinishedTimeTrackingSegments: autoFinishedTimeTrackingSegments.map((s, idx) => ({
        card: s.card,
        type: "autoFinishedTimeTrackingSegment",
        key: `autoFinishedTimeTrackingSegment-${s.card.$meta.get("cardId", idx)}`,
        date: s.createdAt,
      })),
      due: dueCards.map((dc, idx) => ({
        card: dc.card,
        type: "dueCard",
        key: `dueCard-${dc.card.$meta.get("cardId", idx)}`,
        date: new Date(),
      })),
      conv: [],
      owner: ownerInfo.list,
      subbed: subInfo.list,
    };
    resolvables
      .filter((n) => !n.snoozeUntil)
      .forEach((n, idx) => {
        stacks[n.isParticipating ? "conv" : "subbed"].push({
          type: "res",
          notification: n,
          key: `res-${n.resolvable.$meta.get("id", `none-${idx}`)}`,
          date: n.createdAt,
        });
      });

    Object.values(stacks).forEach((list) => list.sort(cmpByDate));

    return {stacks, notisWithoutType};
  }, [notifications, userId]);
};

export default useNotificationStacks;
