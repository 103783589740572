import {monthNames, countWorkdaysForAccount} from "../../lib/date-utils";
import {
  getMilestoneState,
  msToDate,
  setMsThemeColor,
  ThemedMilestoneIcon,
} from "../../features/milestones/milestone-utils";

const getDateLabel = ({account, ms}) => {
  const date = ms.date;
  const msDate = msToDate(ms);
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const diffInDays = Math.round((msDate - todayMidnight.getTime()) / (24 * 3600 * 1000));
  if (diffInDays === 0) {
    return "today";
  } else if (account && diffInDays > 0 && diffInDays < 31) {
    const workdays = countWorkdaysForAccount({account, todayMidnight, targetDay: msDate});
    return `in ${workdays} workday${workdays === 1 ? "" : "s"}`;
  } else {
    return `on ${date.day < 10 ? "0" : ""}${date.day} ${monthNames[date.month - 1].slice(0, 3)} ${
      date.year
    }`;
  }
};

/**
 *
 * @param {object} props
 * @param {import("../../cdx-models/Milestone").Milestone} props.ms
 * @param {import("../../cdx-models/Account").Account} props.account
 * @param {boolean} [props.short]
 * @returns
 */
const MilestoneLabel = ({ms, account, short}) => (
  <div>
    <ThemedMilestoneIcon
      theme={setMsThemeColor(ms)}
      inline
      size={16}
      milestoneState={getMilestoneState(account, ms)}
    />{" "}
    <b>{ms.name}</b>
    {!short && <span> {getDateLabel({account, ms})}</span>}
  </div>
);

export default MilestoneLabel;
