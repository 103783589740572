import {Col, css, DSIconBeast, DSIconBeastSlain, IconProps, Row, Text} from "@cdx/ds";
import {toRomanNumeral} from "../../features/onboarding/onboarding-utils";
import {TooltipForChild} from "@cdx/common";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {CSSProperties} from "react";
import {Account} from "../../cdx-models/Account";
import {Card} from "../../cdx-models/Card";
import {beastStyles} from "./card.css";

const Icon = (props: Pick<IconProps, "size" | "className"> & {isDone?: boolean}) => {
  const {size, className, isDone} = props;
  const iconProps = {size, className, inline: true};
  return isDone ? <DSIconBeastSlain {...iconProps} /> : <DSIconBeast {...iconProps} />;
};

export const BeastPill = ({
  level,
  style,
  size = "md",
  isDone,
  isNewUntil,
}: BeastProps & {style?: CSSProperties}) => (
  <TooltipForChild
    tooltip={`Beast level ${level}. The level increases if a card moves from Run to Run without being completed.${isNewUntil ? " Is marked as 'New' as it was added into a started Sprint." : ""}`}
    placement="bottom"
  >
    <Row
      rounded={4}
      align="center"
      justify="center"
      pointerEvents="auto"
      px={size === "md" ? "4px" : "12px"}
      py={size === "md" ? "4px" : "6px"}
      className={isDone ? beastStyles.type.done : beastStyles.type.notDone}
      minWidth="28px"
      style={style}
      elevation={100}
      lineHeight="none"
      flex="none"
    >
      <Text
        type={size === "md" ? "label10Caps" : "label16"}
        noOverflow
        className={css({whiteSpace: "nowrap"})}
      >
        {size === "md" ? (
          <Icon size={16} className={iconStyles.setSize["12px"]} isDone={isDone} />
        ) : (
          <Icon size={20} isDone={isDone} />
        )}{" "}
        {isNewUntil ? "NEW" : toRomanNumeral(level)}
      </Text>
    </Row>
  </TooltipForChild>
);

type BeastProps = {
  level: number;
  size?: "md" | "lg";
  isDone?: boolean;
  isNewUntil?: string | null;
};
const Beast = (props: BeastProps) => (
  <Col
    absolute
    left="0"
    right="0"
    bottom="0"
    align="center"
    zIndex={1}
    pointerEvents="none"
    style={{marginBottom: 0}}
  >
    <BeastPill style={{marginBottom: props.size === "lg" ? -12 : -8}} {...props} />
  </Col>
);

export const getBeastLevel = (account: Account, card: Card) => {
  // const beastLevel = card.createdAt.getTime() % 2 && card.createdAt.getTime() % 5;
  // return beastLevel;
  if (process.env.REACT_APP_MODE === "open") return 0;
  if (card.isDoc) return 0;
  return card.meta?.sprintLockedIn?.level || 0;
};

export default Beast;
