import {MutableRefObject, ReactElement, useEffect, useRef, useState} from "react";
import {create} from "zustand";
import range from "lodash/range";
import {subscribeWithSelector} from "zustand/middleware";
import {
  ArrowOverlay,
  OverlayPlacer,
  Portal,
  springConfigs,
  useGlobalKeyPress,
  useClickOutside,
  XText,
  useDelayedTrigger,
  useMultiGlobalKeyPress,
  GlobalKeyPressOpts,
  useEsc,
} from "@cdx/common";
import {api} from "../../lib/api";
import {useInstance, useRoot} from "../../lib/mate/mate-utils";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {
  cardSelectionActions,
  getSelectedCardIds,
} from "../../features/card-selection/useSelectedCards";
import {
  peformCardDeletion,
  performBulkArchiveAction,
} from "../../features/card-container/performArchiveAction";
import {useTransition} from "react-spring";
import {TagPicker} from "../PropEdit/TagPicker";
import {moveCardsToDeck} from "../../lib/cross-project-cards-or-decks";
import {CardPickerOverlay} from "../../features/selection-header/AddToParentMenu";
import messenger from "../../lib/messenger";
import {
  canAddHandToQueueErrors,
  canDiscardHandFromQueueErrors,
  getBookmarkInfo,
  isCardInUsersHandQueue,
} from "../../features/hand-queue/hand-queue-utils";
import {performStartAction} from "../../features/card-container/performStartAction";
import {getUpvoteInfo} from "../../features/upvotes/upvote-utils";
import {
  addCardsAsBookmark,
  removeCardsAsBookmark,
} from "../../features/card-container/performHandMoveAction";
import {
  hasPermissionToBookmarkCard,
  hasPermissionToGuardCard,
  hasPermissionToModifyCard,
  hasPermissionToModifyCardId,
  hasPermissionToModifySomeCardInOrg,
} from "../../lib/permissions";
import {getStatusForCard} from "../../lib/card-status-utils";
import {
  AssigneeListPicker,
  DeckListPicker,
  EffortListPicker,
  MilestoneListPicker,
  PrioListPicker,
  SprintListPicker,
} from "../RichTextarea/Lexical/CardPropChangeOverlay";
import {Col} from "@cdx/ds";
import {getCardProjectIfRestricted} from "../../features/card-container/card-container-utils";
import {ZoneSelector, getCardOrders} from "../../features/card-panel/manually-ordererd-card-utils";
import {Card, CardId} from "../../cdx-models/Card";
import {ArenaCtx} from "../../features/card-panel/card-panel-utils";
import {Root} from "../../cdx-models/Root";
import {ProjectId} from "../../cdx-models/Project";
import {WorkflowItemId} from "../../cdx-models/WorkflowItem";
import {performDoneAction} from "../../features/card-container/performDoneAction";

const numberKeys = range(10).map((c) => `${c}`);
const prioKeys = numberKeys.slice(0, 4);

const prioNumToValue: Record<string, null | string> = {"0": null, "1": "c", "2": "b", "3": "a"};

type CardHoverType =
  | "BIG_CARD"
  | "MINI_CARD"
  | "MINI_WORKFLOW_ITEM"
  | "BIG_WORKFLOW_ITEM"
  | "MINI_DEFAULT_CARD"
  | "BIG_DEFAULT_CARD";
type MiniCardHoverType = "MINI_CARD" | "MINI_WORKFLOW_ITEM" | "MINI_DEFAULT_CARD";

type FullCardHoverInfo = {
  type: MiniCardHoverType;
  cardId: CardId;
  cardNodeRef: MutableRefObject<HTMLElement | null>;
  cardContainerKey: string;
  mode: "keyboard" | "mouse";
};

type HoverCardStore = {
  info: null | {type: Exclude<CardHoverType, MiniCardHoverType>} | FullCardHoverInfo;
  prop: null | "effort" | "priority";
};

export const useHoverCardStore = create(
  subscribeWithSelector<HoverCardStore>((set) => ({
    info: null,
    prop: null,
  }))
);

export const useCardHoverHandlers = ({type}: {type: Exclude<CardHoverType, MiniCardHoverType>}) => {
  return {
    onMouseEnter: () => {
      useHoverCardStore.setState({info: {type}});
    },
    onMouseLeave: () => {
      useHoverCardStore.setState({info: null});
    },
  };
};

export const useCardHoverHandlersWithSelection = ({
  isSelecting,
  type,
  cardId,
  cardNodeRef,
  cardContainerKey,
}: Omit<FullCardHoverInfo, "mode"> & {isSelecting: boolean}) => {
  const [waitedForSelection, setWaitedForSelection] = useState(false);
  const waitTrigger = useDelayedTrigger();

  const events = {
    onMouseEnter: () => {
      if (!isSelecting) waitTrigger.fire(() => setWaitedForSelection(true), 300);
      useHoverCardStore.setState({
        info: {type, cardId, cardNodeRef, cardContainerKey, mode: "mouse"},
      });
    },
    onMouseLeave: () => {
      setWaitedForSelection(false);
      const {info} = useHoverCardStore.getState();
      if (info && "mode" in info && info.mode === "mouse") {
        useHoverCardStore.setState({info: null, prop: null});
      }
      waitTrigger.cancel();
    },
  };
  return {waitedForSelection, events};
};

export const usePropOnHoverChange = ({
  type,
  updateFn,
}: {
  type: CardHoverType;
  updateFn?: (e: any) => any;
}) => {
  const handleEffortChange = (e: KeyboardEvent) => {
    const {info, prop} = useHoverCardStore.getState();
    if (!info || !updateFn) return false;
    if (info.type !== type) return false;
    if (prop !== "effort") return false;
    const num = parseInt(e.key, 10);
    updateFn({info, effort: num});
  };

  const handlePrioChange = (e: KeyboardEvent) => {
    const {info, prop} = useHoverCardStore.getState();
    if (!info || !updateFn) return false;
    if (info.type !== type) return false;
    if (prop !== "priority") return false;
    const num = e.key;
    updateFn({info, priority: prioNumToValue[num]});
  };

  useMultiGlobalKeyPress({
    keys: numberKeys,
    descriptionKey: "0-9",
    fn: handleEffortChange,
    description: "Assign effort to card if mouse is over the effort icon.",
    category: "Card",
    disabled: !updateFn,
  });

  useMultiGlobalKeyPress({
    keys: prioKeys,
    descriptionKey: "0-3",
    fn: handlePrioChange,
    description: "Assign priority to card if mouse is over the priority icon.",
    category: "Card",
    disabled: !updateFn,
  });
};

type WithSelectProps<T> = {
  selectProps: (card: Card) => T;
  fnWithSelectProps: (args: T, nodeRef: MutableRefObject<HTMLElement | null>) => void;
  fn?: undefined;
};
type WithoutSelectProps = {
  selectProps?: undefined;
  fnWithSelectProps?: undefined;
  fn: (
    args: {cardId: CardId; cardContainerKey: string},
    nodeRef: MutableRefObject<HTMLElement | null>
  ) => void;
};

type HoverKeyArgs<T> = Omit<GlobalKeyPressOpts, "category" | "description" | "fn"> & {
  description: string;
  modifiesCard?: boolean;
  canModifySomeCard?: boolean | null;
} & (WithSelectProps<T> | WithoutSelectProps);

const useHoverKey = <T extends any>({
  key,
  code,
  selectProps,
  fnWithSelectProps,
  fn,
  description,
  disabled: rawDisabled,
  modifiesCard,
  canModifySomeCard,
  withShift,
}: HoverKeyArgs<T>) => {
  const disabled = rawDisabled || (modifiesCard && !canModifySomeCard);
  useGlobalKeyPress({
    key,
    code,
    withShift,
    fn: () => {
      const {info} = useHoverCardStore.getState();
      if (info && info.type === "MINI_CARD") {
        void new Promise(async () => {
          const {cardId, cardNodeRef, cardContainerKey} = info;
          if (selectProps) {
            const {hasPermission, args} = await waitForResultPromise(() => {
              const card = api.getModel({modelName: "card", id: cardId});
              if (modifiesCard && !hasPermissionToModifyCard({root: api.getRoot(), card})) {
                return {hasPermission: false, args: null};
              }
              return {hasPermission: true, args: card ? selectProps(card) : null};
            });
            if (!hasPermission) {
              messenger.send("You don't have permissions to modify this card");
            } else {
              if (args) fnWithSelectProps(args as any, cardNodeRef);
            }
          } else {
            const cannotModify =
              modifiesCard &&
              (await waitForResultPromise(
                () => !hasPermissionToModifyCardId({root: api.getRoot(), cardId})
              ));
            if (cannotModify) {
              messenger.send("You don't have permissions to modify this card");
            } else {
              fn({cardId, cardContainerKey}, cardNodeRef);
            }
          }
        });
      } else {
        return false;
      }
    },
    description,
    category: "Card",
    disabled,
  } as GlobalKeyPressOpts);
};

const Overlay = ({onClose, ...props}: any) => {
  const handlers = useClickOutside(onClose);
  return <ArrowOverlay arrowSize="sm" {...handlers} {...props} />;
};

type OverlayData = {
  type: string;
  cardId: CardId;
  node: HTMLElement;
  element: ReactElement;
};

const Picker = ({overlayData, onClose}: {overlayData: OverlayData | null; onClose: () => void}) => {
  const transition = useTransition(overlayData, {
    key: (item: OverlayData | null) => item && `${item.type}:${item.cardId}`,
    from: {value: 0},
    enter: {value: 1},
    leave: {value: 0.001},
    config: springConfigs.quick,
  });

  return transition(
    (animProps, item) =>
      item && (
        <Portal>
          <OverlayPlacer
            placement="bottom"
            distanceFromAnchor={10}
            node={item.node}
            presenceProps={animProps}
            renderOverlay={(overlayProps: any) => (
              <Overlay {...overlayProps} onClose={onClose}>
                {item.element}
              </Overlay>
            )}
          />
        </Portal>
      )
  );
};
const AssigneeOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      <AssigneeListPicker
        root={root}
        currentOptionKey={card.assignee?.id ?? null}
        getCurrentProjects={() => (card.deck ? [card.deck.project] : [])}
        onChange={({assigneeId}) =>
          api.mutate.cards.update({id: cardId, assigneeId}).then(() => {
            onClose();
            messenger.send(`Updated owner`);
          })
        }
      />
    </Col>
  );
};

const TagOverlay = ({cardId}: {cardId: CardId}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col pa="12px" style={{width: 210}}>
      {card.deck ? (
        <TagPicker
          root={root}
          card={card}
          project={card.deck && card.deck.project}
          value={card.masterTags}
          onChange={({masterTags}: any) => api.mutate.cards.update({id: cardId, masterTags})}
        />
      ) : (
        <Col sp="8px" pb="12px">
          <XText preset="bold" size={2}>
            Project Tags
          </XText>
          <XText size={2} color="gray600">
            You can't pick project cards as this card is in no deck.
          </XText>
        </Col>
      )}
    </Col>
  );
};
const EffortOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col pa="12px" style={{width: 210}} sp="8px">
      {card.isDoc ? (
        <XText size={2} color="gray600" align="center">
          Can't set effort for Doc cards
        </XText>
      ) : (
        <EffortListPicker
          root={root}
          currentOptionKey={card.effort}
          onChange={({effort}) =>
            api.mutate.cards.update({id: cardId, effort}).then(() => {
              onClose();
              messenger.send(`Updated effort`);
            })
          }
        />
      )}
    </Col>
  );
};

const PriorityOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      {card.isDoc ? (
        <XText size={2} color="gray600" align="center">
          Can't set priority for Doc cards
        </XText>
      ) : (
        <PrioListPicker
          root={root}
          currentOptionKey={card.priority}
          onChange={({priority}) =>
            api.mutate.cards.update({id: cardId, priority}).then(() => {
              onClose();
              messenger.send(`Updated priority`);
            })
          }
        />
      )}
    </Col>
  );
};

const MilestoneOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card: Card = useInstance("card", cardId);
  if (!card) return "No Card found";
  const getCurrentProjectIds = (): ProjectId[] => {
    const projectId = card.deck?.project.$meta.get("id", null) as ProjectId;
    return projectId ? [projectId] : [];
  };
  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      <MilestoneListPicker
        root={root}
        currentOptionKey={card.milestone?.id ?? null}
        getCurrentProjectIds={getCurrentProjectIds}
        onChange={({milestoneId}) =>
          api.mutate.cards.update({id: cardId, milestoneId}).then(() => {
            onClose();
            messenger.send(`Updated Milestone`);
          })
        }
      />
    </Col>
  );
};

const SprintOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  const getCurrentProjectIds = (): ProjectId[] => {
    const projectId = card.deck?.project.$meta.get("id", null) as ProjectId;
    return projectId ? [projectId] : [];
  };

  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      <SprintListPicker
        root={root}
        currentOptionKey={card.sprint?.id ?? null}
        getCurrentProjectIds={getCurrentProjectIds}
        onChange={({sprintId}) =>
          api.mutate.cards.update({id: cardId, sprintId}).then(() => {
            onClose();
            messenger.send(`Updated Run`);
          })
        }
      />
    </Col>
  );
};

const ParentCardOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col>
      <Col px="16px" pt="8px">
        <XText preset="bold" color="gray700" size={2}>
          Set parent card
        </XText>
      </Col>
      <CardPickerOverlay
        onDone={() => {
          onClose();
          messenger.send(`Updated parent card`);
        }}
        childCardIds={[cardId]}
        cards={[card]}
        root={root}
        limit={5}
      />
    </Col>
  );
};

const DeckOverlay = ({cardId, onClose}: {cardId: CardId; onClose: () => void}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  if (!card) return "No Card found";
  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      <DeckListPicker
        root={root}
        currentOptionKey={card.deck?.id ?? null}
        onChange={({deckId}) =>
          moveCardsToDeck([cardId], deckId).then(() => {
            onClose();
            messenger.send(`Updated deck`);
          })
        }
        getProjects={() => getCardProjectIfRestricted({card, root})}
      />
    </Col>
  );
};

export const ZoneOverlay = ({
  cardId,
  onClose,
  arenaCtx,
}: {
  cardId: CardId;
  onClose: () => void;
  arenaCtx: ArenaCtx;
}) => {
  useEsc(onClose);
  const root = useRoot();
  const card = useInstance("card", cardId);
  const cardOrders = getCardOrders({account: root.account, arenaCtx});
  if (!card) return "No Card found";
  return (
    <Col pa="12px" width="cardPropChangeOverlay">
      <ZoneSelector
        arenaCtx={arenaCtx}
        currentLabel={cardOrders[cardId]?.label ?? null}
        onChange={(label) =>
          api.mutate.cardOrders
            .addAfter({
              targetId: null,
              cardIds: [cardId],
              context: arenaCtx.type,
              label,
            })
            .then(onClose)
        }
      />
    </Col>
  );
};

type HoverShortcutArgs = {
  root: Root;
  activeCard: Card | null;
  goToCard: (opts: {cardId: CardId; targetPanel: string}) => void;
  manualOrderLabels: string[];
  arenaCtx: ArenaCtx;
};

export const useHoverShortcuts = ({
  root,
  activeCard,
  goToCard,
  manualOrderLabels,
  arenaCtx,
}: HoverShortcutArgs) => {
  const meId = root.loggedInUser && root.loggedInUser.$meta.get("id", null);
  const hasActiveCard = !!activeCard;
  const [overlayData, setOverlayData] = useState<null | OverlayData>(null);
  const canModifySomeCard = hasPermissionToModifySomeCardInOrg(root);

  const toggleOverlayData = (item: OverlayData) =>
    setOverlayData((prev) =>
      prev && item && prev.type === item.type && prev.cardId === item.cardId ? null : item
    );
  const closeOverlay = () => setOverlayData(null);

  useHoverKey({
    key: " ",
    modifiesCard: true,
    canModifySomeCard,
    disabled: process.env.REACT_APP_MODE === "open",
    selectProps: (card) => ({
      assigneeId: card.assignee && card.assignee.id,
      cardId: card.cardId as CardId,
      status: card.status,
      isDoc: card.isDoc,
    }),
    fnWithSelectProps: ({assigneeId, cardId, status, isDoc}) => {
      if (assigneeId !== meId) {
        api.mutate.cards.update({id: cardId, assigneeId: meId});
      } else if (!isDoc) {
        if (status === "started") {
          api.mutate.cards.update({id: cardId, status: "not_started"});
        } else if (status === "not_started") {
          performStartAction({cardIds: [cardId]});
        }
      }
    },
    description:
      "Assign card to self if hovered. If card is assigned already, start card. Or mark as non-started if it's started.",
  });

  useHoverKey({
    key: "x",
    disabled: process.env.REACT_APP_MODE === "open",
    fn: ({cardId, cardContainerKey}) => {
      if (getSelectedCardIds().has(cardId)) {
        cardSelectionActions.removeSelectedCardIds([cardId], cardContainerKey);
      } else {
        cardSelectionActions.addSelectedCardIds([cardId], cardContainerKey);
      }
    },
    description: "Select/deselect card to if hovered.",
  });

  useHoverKey({
    key: "c",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    fn: ({cardId}) =>
      goToCard({
        cardId,
        targetPanel: "comments",
      }),
    description: "Open hovered card and conversation section",
  });

  useHoverKey({
    key: "Backspace",
    modifiesCard: true,
    canModifySomeCard,
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    fn: ({cardId}) => peformCardDeletion({cardId}),
    description: "Delete hovered card",
  });

  useHoverKey({
    key: "r",
    modifiesCard: true,
    canModifySomeCard,
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    selectProps: (card) => ({
      cardId: card.cardId as CardId,
      visibility: card.visibility,
      mayGuard: hasPermissionToGuardCard(api.getRoot(), card),
    }),
    fnWithSelectProps: ({cardId, visibility, mayGuard}) => {
      if (!mayGuard) {
        messenger.send(`Only Guardians may archive cards`, {type: "error"});
        return;
      }
      if (visibility === "default") {
        performBulkArchiveAction([cardId]);
      } else {
        api.mutate.cards.update({id: cardId, visibility: "default"});
      }
    },
    description: "Archive/unarchive hovered card",
  });

  useHoverKey({
    key: "u",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: false,
    canModifySomeCard,
    selectProps: (card) => ({
      cardId: card.cardId as CardId,
      upvoteInfo: getUpvoteInfo({card, root: api.getRoot()}),
      mayUpvote: card.deck && card.deck.project.allowUpvotes,
    }),
    fnWithSelectProps: ({upvoteInfo, cardId, mayUpvote}) => {
      if (!mayUpvote) {
        messenger.send("This card's project does not allow upvotes.", {type: "error"});
        return;
      }
      return upvoteInfo.myUpvoteId
        ? api.mutate.votes
            .unvote({id: upvoteInfo.myUpvoteId, cardId})
            .then(() => messenger.send("Upvote removed"))
        : api.mutate.votes.vote({cardId}).then(() => messenger.send("Card upvoted"));
    },
    description: "Upvote hovered card",
  });

  useHoverKey({
    key: "w",
    modifiesCard: true,
    canModifySomeCard,
    disabled: process.env.REACT_APP_MODE === "open",
    selectProps: (card) => ({
      status: getStatusForCard(card),
      cardId: card.cardId as CardId,
      mayGuard: hasPermissionToGuardCard(api.getRoot(), card),
    }),
    fnWithSelectProps: ({status, cardId, mayGuard}) => {
      if (!mayGuard) {
        messenger.send(`Only Guardians may set card as done`, {type: "error"});
        return;
      }
      if (status === "done") {
        return api.mutate.cards.update({id: cardId, status: "not_started"});
      }
      const validStatus = ["assigned", "unassigned", "snoozing", "started"];
      if (!validStatus.includes(status)) {
        messenger.send(`Can't complete card that is '${status}'`, {type: "error"});
        return;
      }
      performDoneAction({cardIds: [cardId]});
    },
    description: "Toggle 'done' status of hovered card",
  });

  useHoverKey({
    key: ".",
    modifiesCard: false,
    canModifySomeCard,
    disabled:
      process.env.REACT_APP_MODE === "open" || hasActiveCard || !hasPermissionToBookmarkCard(root),
    selectProps: (card) => {
      const innerRoot = api.getRoot();
      return {
        card,
        bookmarkInfo: getBookmarkInfo({card, root: innerRoot}),
      };
    },
    fnWithSelectProps: ({card, bookmarkInfo}) => {
      if (bookmarkInfo.disabled) {
        messenger.send(bookmarkInfo.tooltip, {type: "error"});
      } else {
        const {loggedInUser} = api.getRoot();
        if (bookmarkInfo.isActive) {
          return removeCardsAsBookmark({cards: [card], loggedInUser});
        } else {
          return addCardsAsBookmark({cards: [card], loggedInUser});
        }
      }
    },
    description: "Bookmark hovered card",
  });

  const handActions = {
    selectProps: (card: Card) => {
      const innerRoot = api.getRoot();
      return {
        cardId: card.cardId as CardId,
        addError: canAddHandToQueueErrors(card, meId, innerRoot),
        discardError: canDiscardHandFromQueueErrors(card),
        inQueue: Boolean(
          isCardInUsersHandQueue({
            cardId: card.cardId as CardId,
            account: innerRoot.account,
            userId: meId,
          })
        ),
      };
    },
    fnWithSelectProps: ({
      cardId,
      inQueue,
      addError,
      discardError,
    }: {
      cardId: CardId;
      inQueue: boolean;
      addError: string | null;
      discardError: string | null;
    }) => {
      if (inQueue) {
        if (discardError) {
          messenger.send(`Can't discard: ${discardError}`, {type: "error"});
          return;
        }
        return api.mutate.handQueue
          .removeCards({cardIds: [cardId]})
          .then(() => messenger.send(`Card removed from hand`));
      } else {
        if (addError) {
          messenger.send(`${addError} to hand`);
          return;
        } else {
          return api.mutate.handQueue
            .addCardsToOwner({cardIds: [cardId], accountId: root.account.id})
            .then(() => messenger.send(`Card added to hand`));
        }
      }
    },
  };
  useHoverKey({
    key: "h",
    modifiesCard: true,
    canModifySomeCard,
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    ...handActions,
    description: "Add/remove hovered card to hand",
  });

  useHoverKey({
    key: "o",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "owner",
        cardId,
        element: <AssigneeOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Change owner of hovered card",
  });

  useHoverKey({
    key: "t",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "tags",
        cardId,
        element: <TagOverlay cardId={cardId} />,
        node: nodeRef.current,
      }),
    description: "Change projects tags of hovered card",
  });
  useHoverKey({
    key: "e",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "effort",
        cardId,
        element: <EffortOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Change effort of hovered card",
  });
  useHoverKey({
    key: "p",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "priority",
        cardId,
        element: <PriorityOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Change priority of hovered card",
  });
  useHoverKey({
    key: "m",
    disabled:
      process.env.REACT_APP_MODE === "open" || hasActiveCard || !root.account.milestonesEnabled,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "milestone",
        cardId,
        element: <MilestoneOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Change milestone of hovered card",
  });
  useHoverKey({
    key: ",",
    disabled:
      process.env.REACT_APP_MODE === "open" || hasActiveCard || !root.account.sprintsEnabled,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "sprint",
        cardId,
        element: <SprintOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Change Run of hovered card",
  });
  useHoverKey({
    key: "s",
    disabled:
      process.env.REACT_APP_MODE === "open" ||
      hasActiveCard ||
      root.account.workflowMode === "none",
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) =>
      nodeRef &&
      nodeRef.current &&
      toggleOverlayData({
        type: "parentCard",
        cardId,
        element: <ParentCardOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      }),
    description: "Set parent card of hovered card",
  });

  useHoverKey({
    key: "d",
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard,
    modifiesCard: true,
    canModifySomeCard,
    selectProps: (card) => ({
      cardId: card.cardId as CardId,
      mayGuard: hasPermissionToGuardCard(api.getRoot(), card),
    }),
    fnWithSelectProps: ({cardId, mayGuard}, nodeRef: MutableRefObject<HTMLElement | null>) => {
      if (!mayGuard) {
        messenger.send("Only Guardians may change this card's deck", {type: "error"});
        return;
      }
      if (!nodeRef || !nodeRef.current) return;
      toggleOverlayData({
        type: "deck",
        cardId,
        element: <DeckOverlay cardId={cardId} onClose={closeOverlay} />,
        node: nodeRef.current,
      });
    },
    description: "Change deck of hovered card",
  });

  useHoverKey({
    code: "KeyD",
    withShift: true,
    disabled: process.env.REACT_APP_MODE === "open" || hasActiveCard || !manualOrderLabels,
    modifiesCard: true,
    canModifySomeCard,
    fn: ({cardId}, nodeRef) => {
      if (!nodeRef || !nodeRef.current) return;
      toggleOverlayData({
        type: "zone",
        cardId,
        element: <ZoneOverlay cardId={cardId} onClose={closeOverlay} arenaCtx={arenaCtx} />,
        node: nodeRef.current,
      });
    },
    description: "Change zone of hovered card",
  });

  return <Picker overlayData={overlayData} onClose={() => setOverlayData(null)} />;
};

export const useWorkflowItemHoverShortcuts = ({
  canModify,
  onCardSelect,
  onSelectAll,
}: {
  canModify: boolean | null;
  onCardSelect: (itemId: WorkflowItemId) => void;
  onSelectAll: () => void;
}) => {
  useGlobalKeyPress({
    key: "x",
    fn: () => {
      const {info} = useHoverCardStore.getState();
      if (info && info.type === "MINI_WORKFLOW_ITEM") {
        onCardSelect(info.cardId as any as WorkflowItemId);
      } else {
        return false;
      }
    },
    description: "Select/deselect card if hovered.",
    category: "Journey Step",
    disabled: !canModify,
  });
  useGlobalKeyPress({
    key: "a",
    withMod: true,
    fn: onSelectAll,
    description: "Select all cards",
    category: "Journey Step",
  });
};

export const usePropHover = (prop: "effort" | "priority") => {
  const isActiveRef = useRef(false);
  useEffect(() => {
    return () => {
      if (isActiveRef.current) {
        useHoverCardStore.setState({prop: null});
      }
    };
  }, []);
  return {
    onMouseOver: () => {
      if (!isActiveRef.current) {
        useHoverCardStore.setState({prop});
        isActiveRef.current = true;
      }
    },
    onMouseLeave: () => {
      if (isActiveRef.current) {
        useHoverCardStore.setState({prop: null});
        isActiveRef.current = false;
      }
    },
  };
};
