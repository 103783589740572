import {
  Box,
  css,
  DSIconDeck,
  DSIconEffort,
  DSIconMilestone,
  DSIconPrioA,
  DSIconPrioB,
  DSIconPrioC,
  DSIconSprint,
  DSIconUser,
  Row,
  DSListSelector,
  DSListItem,
} from "@cdx/ds";
import {cloneElement, ReactNode} from "react";
import {checkIfPresent, useRoot} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import {getRawSelectedProjects, orderProjects} from "../../../lib/hooks/useSelectedProjects";
import {prettyShortWorkdays, toCmpDate} from "../../../lib/date-utils";
import {
  ThemedMilestoneIcon,
  getMilestoneState,
  msToDate,
  setMsThemeColor,
} from "../../../features/milestones/milestone-utils";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {DeckPickerImage} from "../../../features/card-container/DSDeckPicker";
import {projectsUsers, sortedProjectDecks} from "../../../lib/utils";
import {groupByFn} from "../../../lib/collection-utils";
import {hasPermissionToGuardCard} from "../../../lib/permissions";
import {Root} from "../../../cdx-models/Root";
import {Card} from "../../../cdx-models/Card";
import {
  SprintTileContent,
  getActiveAndFutureSprints,
  getPastSprints,
  sprintLabel,
} from "../../../features/milestones/sprint-utils";
import {Milestone} from "../../../cdx-models/Milestone";
import {Sprint} from "../../../cdx-models/Sprint";
import {Project, ProjectId} from "../../../cdx-models/Project";
import {ProjectSpace, ProjectSpaceId} from "../../../cdx-models/utils/extended-fields";
import {Deck} from "../../../cdx-models/Deck";
import {getCardProjectIfRestricted} from "../../../features/card-container/card-container-utils";
import {UserId} from "../../../cdx-models/User";
import DSAvatar from "../../DSAvatar/DSAvatar";
import CardPropChangeOverlayLayout, {TabDef} from "./CardPropChangeOverlayLayout";
import {isHeroCard} from "../../../features/workflows/workflow-utils";

type ListPickerProps = {
  root: Root;
  onChange: (arg: any, mode: "enter" | "click" | "navigation") => unknown;
  pendingChanges?: any;
  confirmOnNavigation?: boolean;
};

export type CardPropChangeKey = "deck" | "owner" | "sprint" | "milestone" | "priority" | "effort";

const getSpacesAndDecks = (projects: Project[], filterFn?: (deck: Deck) => boolean) => {
  const spacesAndDecksByProjectId: Record<ProjectId, {space: ProjectSpace; decks: Deck[]}[]> = {};
  for (const project of projects) {
    const spacesAndDecks: {space: ProjectSpace; decks: Deck[]}[] = [];
    let allDecks = sortedProjectDecks(project);
    allDecks = filterFn ? allDecks.filter(filterFn) : allDecks;
    const presentSpaceIds = new Set(project.spaces.map((s) => s.id));
    const defaultSpaceId = project.spaces.length > 0 ? project.spaces[0].id : (0 as ProjectSpaceId);
    const bySpaceId = groupByFn(
      allDecks,
      (d) =>
        (d.spaceId && presentSpaceIds.has(d.spaceId)
          ? d.spaceId
          : defaultSpaceId) as unknown as string
    );
    for (const space of project.spaces) {
      const decks = bySpaceId[space.id];
      if (decks) spacesAndDecks.push({space, decks});
    }
    spacesAndDecksByProjectId[project.id as ProjectId] = spacesAndDecks;
  }
  return spacesAndDecksByProjectId;
};

export const PrioListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
}: ListPickerProps & {currentOptionKey: any}) => {
  const getOptions = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      const labels = root.account.$meta.get("priorityLabels", {
        a: "High",
        b: "Medium",
        c: "Low",
      });
      return [
        {key: null, label: "None", icon: <Box width="16px" />},
        {key: "c", label: labels.c, icon: <DSIconPrioC />},
        {key: "b", label: labels.b, icon: <DSIconPrioB />},
        {key: "a", label: labels.a, icon: <DSIconPrioA />},
      ];
    }, api);
    return isLoaded ? (result as (null | any)[]) : null;
  };
  return (
    <DSListSelector
      label="Choose Priority"
      getOptions={getOptions}
      optionToKey={(p) => p.key}
      optionToSearchString={(p) => p.label}
      renderOption={(p) => (
        <Row sp="4px">
          {cloneElement(p.icon, {className: css({color: "secondary"}, iconStyles.sizes[16])})}
          <span>{p.label}</span>
        </Row>
      )}
      initalSelectionKey={
        pendingChanges && "priority" in pendingChanges ? pendingChanges.priority : currentOptionKey
      }
      currentOptionKey={currentOptionKey}
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(priority, mode) => onUpdate({priority}, mode)}
    />
  );
};

export const EffortListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
}: ListPickerProps & {currentOptionKey: any}) => {
  const getOptions = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      return [null, ...root.account.$meta.get("effortScale", [0, 1, 2, 3, 5, 8])];
    }, api);
    return isLoaded ? (result as (null | any)[]) : null;
  };
  return (
    <DSListSelector
      label="Choose Effort"
      getOptions={getOptions}
      optionToKey={(p) => p ?? null}
      optionToSearchString={(p) => (p === null ? "None" : `${p}`)}
      renderOption={(p) => (p === null ? "None" : p)}
      initalSelectionKey={
        pendingChanges && "effort" in pendingChanges
          ? pendingChanges.effort
          : currentOptionKey ?? null
      }
      currentOptionKey={currentOptionKey}
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(effort, mode) => onUpdate({effort}, mode)}
      createOpts={{
        label: "set custom effort",
        onCreate: (term) => onUpdate({effort: Number(term)}, "enter"),
        toKey: (term) => Number(term),
      }}
    />
  );
};

export const AssigneeListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
  getCurrentProjects,
  label = "Choose Owner",
  noNone,
  exceptIds,
}: ListPickerProps & {
  currentOptionKey?: any;
  getCurrentProjects: () => Project[];
  label?: string;
  noNone?: boolean;
  exceptIds?: Set<UserId>;
}) => {
  const getOptions = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      const getProjects = (): Project[] => {
        if (pendingChanges && "deckId" in pendingChanges) {
          return [api.getModel({modelName: "deck", id: pendingChanges.deckId}).project];
        } else {
          return getCurrentProjects();
        }
      };
      const projects = getProjects();
      let users = [
        ...(noNone ? [] : [null]),
        ...(projects.length === 0 ? [root.loggedInUser] : projectsUsers(root.account, projects)),
      ];
      if (exceptIds) users = users.filter((u) => !exceptIds.has(u?.$meta.get("id", null)));
      users.forEach((u) => u?.$meta.get("id"));
      return users;
    }, api);
    return isLoaded ? (result as (null | any)[]) : null;
  };
  return (
    <DSListSelector
      label={label}
      getOptions={getOptions}
      optionToKey={(u) => u?.$meta.get("id", "none") ?? null}
      optionToSearchString={(u) => (u ? u.name : "none")}
      renderOption={(u) => (
        <Row sp="8px">
          {u ? <DSAvatar user={u} size={20} /> : <Box width="20px" />}
          {u ? <span>{u.name}</span> : <span>None</span>}
        </Row>
      )}
      currentOptionKey={currentOptionKey ?? null}
      initalSelectionKey={
        pendingChanges && "assigneeId" in pendingChanges
          ? pendingChanges.assigneeId
          : currentOptionKey ?? null
      }
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(assigneeId, mode) => onUpdate({assigneeId}, mode)}
    />
  );
};

export const DeckListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
  getProjects,
  allowNone,
  filterFn,
}: ListPickerProps & {
  currentOptionKey: any;
  getProjects: () => Project[];
  allowNone?: {label: string; icon: ReactNode};
  filterFn?: (deck: Deck) => boolean;
}) => {
  const getItems = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      const orderedProjects = orderProjects(root, getProjects());
      const spacesAndDecksByProjectId = getSpacesAndDecks(orderedProjects, filterFn);
      const items: DSListItem<Deck | null>[] = [];

      if (allowNone) {
        items.push({type: "option", value: null});
      }

      const userId = root.loggedInUser.$meta.get("id", null);
      const projectIds = orderedProjects?.map((p) => p.$meta.get("id", null)!).filter(Boolean);

      let historyDecks = userId
        ? root.account.$meta
            .find("deckAssignments", {
              deck: {isDeleted: false, ...(projectIds ? {project: {id: projectIds}} : null)},
              userId,
              $order: "-lastAssignedAt",
              $limit: 5,
            })
            .map((e) => e.deck)
        : [];
      historyDecks = filterFn ? historyDecks.filter(filterFn) : historyDecks;
      if (historyDecks.length) {
        items.push({type: "section", value: "Your last used decks", key: "history"});
        items.push(...historyDecks.map((deck) => ({type: "option", value: deck}) as const));
      }
      items.push(
        ...orderedProjects.flatMap((project) => {
          const spaces = spacesAndDecksByProjectId[project.id as ProjectId]!;
          return spaces.flatMap(({space, decks}) => [
            {
              type: "section",
              value: (
                <Row sp="4px" align="baseline">
                  <Box textWrap="nowrap" textOverflow="ellipsis">
                    {project.name}
                  </Box>
                  {space.name && (
                    <>
                      <Box>-</Box>
                      <Box textWrap="nowrap" textOverflow="ellipsis">
                        {space.name}
                      </Box>
                    </>
                  )}
                </Row>
              ),
              key: `${project.id}:${space.id}`,
            } as const,
            ...decks.map((d) => ({type: "option", value: d}) as const),
          ]);
        })
      );
      return items;
    }, api);
    return isLoaded ? result : null;
  };
  return (
    <DSListSelector
      getItems={getItems}
      optionToKey={(d) => d?.$meta.get("id", null) ?? null}
      optionToSearchString={(d) => (d ? d.title : allowNone?.label || "None")}
      renderOption={(d) =>
        d ? (
          <Row sp="8px">
            <DeckPickerImage deck={d} />
            <span>{d.title}</span>
          </Row>
        ) : (
          <Row sp="8px">
            {allowNone?.icon}
            <span>{allowNone?.label || "None"}</span>
          </Row>
        )
      }
      initalSelectionKey={
        pendingChanges && "deckId" in pendingChanges ? pendingChanges.deckId : currentOptionKey
      }
      currentOptionKey={currentOptionKey}
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(deckId, mode) => onUpdate({deckId}, mode)}
      label="Choose Deck"
    />
  );
};

export const SprintListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
  getCurrentProjectIds,
  onDark,
}: ListPickerProps & {
  currentOptionKey: any;
  getCurrentProjectIds: () => ProjectId[];
  onDark?: boolean;
}) => {
  const getItems = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      const getProjects = (): ProjectId[] => {
        if (pendingChanges && "deckId" in pendingChanges) {
          return [
            api
              .getModel({modelName: "deck", id: pendingChanges.deckId})
              ?.project.$meta.get("id", null),
          ];
        } else {
          return getCurrentProjectIds();
        }
      };
      let projectIds = getProjects().filter(Boolean);
      if (projectIds.length === 0) {
        projectIds = getRawSelectedProjects(root)
          .map((p) => p.$meta.get("id", null) as ProjectId)
          .filter(Boolean);
      }
      const constraints = {
        $and: projectIds.map((projectId) => ({sprintConfig: {sprintProjects: {projectId}}})),
      };
      const futureSprints = getActiveAndFutureSprints({
        account: root.account,
        constraints,
      });

      futureSprints.forEach((sprint) => sprint.$meta.get("id"));
      const pastSprints = getPastSprints({
        account: root.account,
        constraints,
      });
      pastSprints.forEach((sprint) => sprint.$meta.get("id"));

      return [
        {type: "option", value: null},
        {type: "section", value: "Upcoming Runs", key: "future"},
        ...futureSprints.map((value) => ({type: "option", value}) as const),
        {type: "section", value: "Past Runs", key: "past"},
        ...pastSprints.map((value) => ({type: "option", value}) as const),
      ] satisfies DSListItem<Sprint | null>[];
    }, api);
    return isLoaded ? result : null;
  };
  return (
    <DSListSelector
      label="Choose Run"
      getItems={getItems}
      optionToKey={(sprint) => (sprint ? sprint.$meta.get("id", null) : null)}
      optionToSearchString={(sprint) => (sprint ? sprintLabel(sprint) : "None")}
      renderOption={(sprint) =>
        sprint ? (
          <SprintTileContent sprint={sprint} account={root.account} onDark={onDark} />
        ) : (
          <Box color="primary" size={14}>
            None
          </Box>
        )
      }
      currentOptionKey={currentOptionKey}
      initalSelectionKey={
        pendingChanges && "sprintId" in pendingChanges ? pendingChanges.sprintId : currentOptionKey
      }
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(sprintId, mode) => onUpdate({sprintId}, mode)}
    />
  );
};

export const MilestoneListPicker = ({
  root,
  currentOptionKey,
  onChange: onUpdate,
  pendingChanges,
  confirmOnNavigation,
  getCurrentProjectIds,
  onDark,
}: ListPickerProps & {
  currentOptionKey: any;
  getCurrentProjectIds: () => ProjectId[];
  onDark?: boolean;
}) => {
  const getItems = () => {
    const {isLoaded, result} = checkIfPresent(() => {
      const getProjects = (): ProjectId[] => {
        if (pendingChanges && "deckId" in pendingChanges) {
          return [
            api
              .getModel({modelName: "deck", id: pendingChanges.deckId})
              ?.project.$meta.get("id", null),
          ];
        } else {
          return getCurrentProjectIds();
        }
      };
      let projectIds = getProjects().filter(Boolean);
      if (projectIds.length === 0) {
        projectIds = getRawSelectedProjects(root)
          .map((p) => p.$meta.get("id", null) as ProjectId)
          .filter(Boolean);
      }
      const constraints = {
        isDeleted: false,
        $and: projectIds.map((projectId) => ({milestoneProjects: {projectId}})),
      };
      const today = new Date();
      const futureMs = root.account.$meta.find("milestones", {
        date: {op: "gte", value: toCmpDate(today) as any},
        ...constraints,
        $order: "date",
      });
      const pastMs = root.account.$meta.find("milestones", {
        date: {op: "lt", value: toCmpDate(today) as any},
        ...constraints,
        $order: "-date",
      });
      futureMs.forEach((ms) => ms.$meta.get("id"));
      pastMs.forEach((ms) => ms.$meta.get("id"));
      return [
        {type: "option", value: null},
        {type: "section", value: "Upcoming Milestones", key: "future"},
        ...futureMs.map((ms) => ({type: "option", value: ms}) as const),
        {type: "section", value: "Past Milestones", key: "past"},
        ...pastMs.map((ms) => ({type: "option", value: ms}) as const),
      ] satisfies DSListItem<Milestone | null>[];
    }, api);
    return isLoaded ? result : null;
  };

  return (
    <DSListSelector
      label="Choose Milestone"
      getItems={getItems}
      optionToKey={(ms) => (ms ? ms.$meta.get("id", null) : null)}
      optionToSearchString={(ms) => (ms ? ms.name : "None")}
      renderOption={(ms) =>
        ms ? (
          <Row sp="4px">
            <ThemedMilestoneIcon
              theme={setMsThemeColor(ms)}
              size={16}
              inline
              milestoneState={getMilestoneState(root.account, ms)}
              onDark={onDark}
            />
            <Box
              bold
              color="primary"
              size={12}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {ms.name}
            </Box>
            <Box ml="auto" size={12} flex="none" weight="normal">
              {prettyShortWorkdays({
                targetDay: msToDate(ms),
                workdays: root.account.workdays,
              })}
            </Box>
          </Row>
        ) : (
          <Box color="primary" size={14}>
            None
          </Box>
        )
      }
      currentOptionKey={currentOptionKey}
      initalSelectionKey={
        pendingChanges && "milestoneId" in pendingChanges
          ? pendingChanges.milestoneId
          : currentOptionKey
      }
      autoFocus
      confirmOnNavigation={confirmOnNavigation}
      onConfirm={(milestoneId, mode) => onUpdate({milestoneId}, mode)}
    />
  );
};

type PassedProps = {
  root: Root;
  card: Card;
  isNewCard?: boolean;
};

export const getDeckFilterFn = (isNewCard: Boolean | undefined, card: Card) => {
  if (card.isDoc) {
    return (deck: Deck) => deck.allowedCardTypes.includes("doc");
  } else if (isNewCard) {
    return (deck: Deck) =>
      deck.allowedCardTypes.includes("task") || deck.allowedCardTypes.includes("hero");
  } else {
    const isHero = isHeroCard(card);
    if (isHero) {
      return (deck: Deck) => deck.allowedCardTypes.includes("hero");
    } else {
      return (deck: Deck) =>
        deck.allowedCardTypes.includes("task") || deck.allowedCardTypes.includes("hero");
    }
  }
};

const allTabs: TabDef<PassedProps>[] = [
  {
    key: "deck",
    icon: <DSIconDeck />,
    label: "Deck",
    cardProp: "deckId",
    isShown: ({root, card}) => Boolean(hasPermissionToGuardCard(root, card)),
    component: ({card, isNewCard, ...rest}) => (
      <DeckListPicker
        {...rest}
        currentOptionKey={card.deck?.id ?? null}
        getProjects={() => getCardProjectIfRestricted({card, root: rest.root})}
        allowNone={isNewCard ? {label: "No deck", icon: <Box width="16px" />} : undefined}
        filterFn={getDeckFilterFn(isNewCard, card)}
      />
    ),
  },
  {
    key: "owner",
    label: "Owner",
    icon: <DSIconUser />,
    cardProp: "assigneeId",
    isShown: () => true,
    component: ({card, ...rest}) => (
      <AssigneeListPicker
        {...rest}
        currentOptionKey={card.assignee?.id}
        getCurrentProjects={() => (card.deck ? [card.deck.project] : [])}
      />
    ),
  },
  {
    key: "sprint",
    label: "Run",
    cardProp: "sprintId",
    icon: <DSIconSprint />,
    isShown: ({root, card}) => Boolean(root.account.sprintsEnabled || card.sprint),
    component: ({card, ...rest}) => (
      <SprintListPicker
        {...rest}
        currentOptionKey={((card as any).sprintId || card.sprint?.id) ?? null}
        getCurrentProjectIds={() =>
          card.deck ? [card.deck.project.$meta.get("id", null) as ProjectId].filter(Boolean) : []
        }
        onDark
      />
    ),
  },
  {
    key: "milestone",
    label: "Milestone",
    cardProp: "milestoneId",
    icon: <DSIconMilestone />,
    isShown: ({root, card}) => Boolean(root.account.milestonesEnabled || card.milestone),
    component: ({card, ...rest}) => (
      <MilestoneListPicker
        {...rest}
        currentOptionKey={((card as any).milestoneId || card.milestone?.id) ?? null}
        getCurrentProjectIds={() =>
          card.deck ? [card.deck.project.$meta.get("id", null) as ProjectId].filter(Boolean) : []
        }
        onDark
      />
    ),
  },

  {
    key: "priority",
    label: "Priority",
    cardProp: "priority",
    icon: <DSIconPrioA />,
    isShown: ({card}) => !card.isDoc,
    component: ({card, ...rest}) => <PrioListPicker {...rest} currentOptionKey={card.priority} />,
  },
  {
    key: "effort",
    label: "Effort",
    cardProp: "effort",
    icon: <DSIconEffort />,
    isShown: ({card}) => !card.isDoc,
    component: ({card, ...rest}) => <EffortListPicker {...rest} currentOptionKey={card.effort} />,
  },
];

const getCardValue = {
  deckId: (card: Card) => card.deck?.id ?? null,
  assigneeId: (card: Card) => card.assignee?.id ?? null,
  milestoneId: (card: Card) => card.milestone?.id ?? null,
  sprintId: (card: Card) => card.sprint?.id ?? null,
  priority: (card: Card) => card.priority,
  effort: (card: Card) => card.effort,
};

export type CardPropChangeOverlayProps = {
  card: Card;
  onChange: (data: any) => any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
  isNewCard?: boolean;
};

const CardPropChangeOverlay = (props: CardPropChangeOverlayProps) => {
  const {card, onChange, onClose, initialTab, isNewCard} = props;
  const getCurrentValue = (key: any) => getCardValue[key as "deckId"](card);
  const root = useRoot();

  return (
    <CardPropChangeOverlayLayout
      onChange={onChange}
      onClose={onClose}
      initialTab={initialTab}
      passProps={{root, card, isNewCard}}
      tabs={allTabs}
      getCurrentValue={getCurrentValue}
    />
  );
};

export default CardPropChangeOverlay;
