import effort from "./categories/effort";
import priority from "./categories/priority";
import userCategories from "./categories/user-categories";
import cardCategories from "./categories/card-categories";
import deck from "./categories/deck";
import status from "./categories/status";
import tags from "./categories/tags";
import contents from "./categories/contents";
import milestone from "./categories/milestone";
import project from "./categories/project";
import metaCategories from "./categories/meta";
import sprints from "./categories/sprint";
import spaceCategory from "./categories/space";
import zoneCategory from "./categories/zone";
import queueCategory from "./categories/queue";

export const searchCategories = [
  ...cardCategories,
  effort,
  priority,
  status,
  ...tags,
  ...userCategories,
  deck,
  milestone,
  ...sprints,
  project,
  spaceCategory,
  zoneCategory,
  queueCategory,
  ...metaCategories,
  ...contents,
];

export const searchCategoriesByKey = searchCategories.reduce((m, c) => {
  m[c.key] = c;
  return m;
}, {});
