import {cx} from "@cdx/common";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {animated, useSpring} from "@react-spring/web";
import {forwardRef} from "react";
import {potionIconStyles} from "./PotionIcon.css";

type PotionIconProps = {
  active?: boolean;
  progress?: number;
  size?: keyof typeof iconStyles.sizes;
  className?: string;
  alt?: string;
};

const ID = `cdxPotionIconMask`;

export const PotionIcon = forwardRef<SVGSVGElement, PotionIconProps>(
  ({className, size = 20, alt, progress = 0.6, active}, ref) => {
    const spring = useSpring({progress});
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        ref={ref}
        className={cx(
          iconStyles.base,
          iconStyles.sizes[size],
          active ? potionIconStyles.active : potionIconStyles.base,
          className
        )}
      >
        {alt && <title>{alt}</title>}
        <path
          d="M10.5 7C10 7 9 6.3 9 5.5S9.7 4 10.5 4h3c.8 0 1.5.7 1.5 1.5S14 7 13.5 7m-3 .1A7.6 7.6 0 0 0 4 14.5c0 4.1 3.6 7.5 8 7.5s8-3.4 8-7.5c0-3.7-2.8-6.7-6.5-7.4"
          strokeWidth="1.5"
          className="stroke"
          fill="none"
        />
        <mask id={ID} style={{maskType: "alpha"}}>
          <ellipse cx="12" cy="14.5" rx="6" ry="5.5" fill="black" />
        </mask>
        <animated.rect
          x="4"
          y={spring.progress.to((val) => 18 - val * 9)}
          width="16"
          height={spring.progress.to((val) => 2 + val * 9)}
          mask={`url(#${ID})`}
          className="secondary-fill"
        />
      </svg>
    );
  }
);
