import {useSlashCommand} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import {textUpdateSelectionHandler} from "../../components/RichTextarea/Lexical/CdxTypeAheadLexicalPlugin";
import {ConvoAction} from "../card-container/sidebars/conversations/get-convo-actions";

const CloseCommand = ({
  closeAction,
  commentIfContent,
  commandKey,
}: {
  closeAction: ConvoAction;
  commentIfContent: () => Promise<void>;
  commandKey: string;
}) => {
  useSlashCommand({
    key: commandKey,
    label: closeAction.message,
    keywords: `close resolve submit cancel ${closeAction.message}`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        closeAction.onClick?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

const DoneCommand = ({
  closeAction,
  commentIfContent,
  onDone,
}: {
  closeAction: ConvoAction;
  commentIfContent: () => Promise<void>;
  onDone: (() => Promise<unknown>) | null;
}) => {
  useSlashCommand({
    key: "done",
    label: `${closeAction.message} and mark Card as done`,
    keywords: `close card mark done`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        await closeAction.onClick?.();
        onDone?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

const OptOutCommand = ({
  optOutAction,
  commentIfContent,
}: {
  optOutAction: ConvoAction;
  commentIfContent: () => Promise<void>;
}) => {
  useSlashCommand({
    key: "opt-out",
    label: optOutAction.message,
    keywords: `opt-out leave approve ${optOutAction.message}`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        optOutAction.onClick?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

type ConvoEditorCommandsPluginProps = {
  actions: ConvoAction[];
  commentIfContent: () => Promise<void>;
  onDone: (() => Promise<unknown>) | null;
};
export const ConvoEditorCommandsPlugin = ({
  actions,
  commentIfContent,
  onDone,
}: ConvoEditorCommandsPluginProps) => {
  const closeAndDoneAction = actions.find((a) => a.type === "close-and-mark-done");
  const closeAction = actions.find((a) => a.type === "close" && !a.disabled);
  const optOutAction = actions.find(
    (a) => (a.type === "approve-and-leave" || a.type === "opt-out") && !a.disabled
  );
  const canMarkDone = () => {
    if (closeAndDoneAction && closeAndDoneAction.disabled) return false;
    if (closeAction && closeAction.disabled) return false;
    return true;
  };
  const hasCloseAndDoneAction = closeAndDoneAction && !closeAndDoneAction.disabled;

  return (
    <>
      {closeAction && (
        <CloseCommand
          commandKey="close"
          closeAction={closeAction}
          commentIfContent={commentIfContent}
        />
      )}
      {!hasCloseAndDoneAction && closeAction && canMarkDone() && onDone && (
        <DoneCommand
          closeAction={closeAction}
          commentIfContent={commentIfContent}
          onDone={onDone}
        />
      )}
      {hasCloseAndDoneAction && (
        <CloseCommand
          commandKey="close-and-mark-done"
          closeAction={closeAndDoneAction}
          commentIfContent={commentIfContent}
        />
      )}
      {optOutAction && (
        <OptOutCommand optOutAction={optOutAction} commentIfContent={commentIfContent} />
      )}
    </>
  );
};

export default ConvoEditorCommandsPlugin;
