import {useRef, useEffect, useState, useCallback} from "react";
import {XCol, XText} from "../components/xui";
import {delayedTrigger, MiniEvent} from "@cdx/common";
import {useTransition, animated} from "react-spring";
import {messengerStyles as styles} from "./messenger.css";
import {SimplePortal} from "@cdx/ds";

const messagesEvent = new MiniEvent();

let nextId = 1;

const valToMs = (val) => {
  if (typeof val !== "string") return 1500;
  return Math.max(1500, Math.min(10000, 1000 + val.length * 50));
};

const messenger = {
  /**
   *
   * @param {string} val
   * @param {{type?: "error" | "info", msToRemove?: number}} [options]
   * @returns
   */
  send: (val, {type, msToRemove = valToMs(val)} = {}) =>
    messagesEvent.emit(val, {type, msToRemove}),
};

const Message = ({frontMost, onRemove, message, msToRemove, style, id, type}) => {
  const removeMsRef = useRef(msToRemove);
  useEffect(() => {
    if (frontMost) {
      const trigger = delayedTrigger();
      trigger.fire(() => onRemove(id), removeMsRef.current);
      return () => trigger.cancel();
    }
  }, [frontMost, onRemove, id]);

  return (
    <XCol
      as={animated.div}
      onClick={() => onRemove(id)}
      px={3}
      py={2}
      bg={type === "error" ? "error600" : "blue700"}
      style={style}
      elevation={2}
      absolute
      className={styles.message}
      data-cdx-clickable
    >
      <XText size={2} color="white">
        {message}
      </XText>
    </XCol>
  );
};

export const MessengerArea = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    messagesEvent.addListener((message, {type, msToRemove}) =>
      setMessages((prev) => [...prev, {id: (nextId += 1), message, msToRemove, type}])
    );
  }, []);

  const handleRemove = useCallback((msgId) => {
    setMessages((prev) => prev.filter((msg) => msg.id !== msgId));
  }, []);

  const transition = useTransition(
    messages.map((message, i) => ({message, i: messages.length - i - 1})),
    {
      key: (item) => item.message.id,
      from: ({i}) => ({
        opacity: 0,
        translateY: `${1 + Math.min(i, 6) * 0.25}rem`,
        scale: 1 - Math.min(i, 6) * 0.02,
      }),
      enter: ({i}) => ({
        opacity: 1,
        translateY: `${Math.min(i, 6) * 0.25}rem`,
        scale: 1 - Math.min(i, 6) * 0.02,
      }),
      update: ({i}) => ({
        opacity: 1,
        translateY: `${Math.min(i, 6) * 0.25}rem`,
        scale: 1 - Math.min(i, 6) * 0.02,
      }),
      leave: ({i}) => ({
        opacity: 0.001,
        translateY: `${-0.5 + Math.min(i, 6) * 0.25}rem`,
        scale: 1,
      }),
    }
  );

  return (
    <SimplePortal>
      <div className={styles.outer}>
        {transition((props, {message, i}) => (
          <Message
            id={message.id}
            message={message.message}
            frontMost={i === 0}
            onRemove={handleRemove}
            msToRemove={message.msToRemove}
            type={message.type}
            style={{
              pointerEvents: props.opacity.to((val) => (val > 0.9 ? "initial" : "none")),
              ...props,
            }}
          />
        ))}
      </div>
    </SimplePortal>
  );
};

export default messenger;
