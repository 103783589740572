import {api} from "../../lib/api";
import confirm from "../../lib/confirm";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {Col, css, DSButton, Row, Text} from "@cdx/ds";
import {CardLink} from "../activity-feed/shared";
import {Card, CardId} from "../../cdx-models/Card";

const checkCards = (
  cardIds: CardId[]
): Promise<{
  incompleteCardInfos: CardInfo[];
}> =>
  waitForResultPromise(() => {
    const freshCards = cardIds.map((cardId) =>
      api.getModel({modelName: "card", id: cardId})
    ) as Card[];
    return {
      incompleteCardInfos: freshCards
        .filter(
          (freshCard) =>
            freshCard.checkboxStats.total > 0 &&
            freshCard.checkboxStats.checked < freshCard.checkboxStats.total
        )
        .map((c) => ({id: c.cardId as CardId, stats: c.checkboxStats})),
    };
  });

type CardInfo = {
  id: CardId;
  stats: Card["checkboxStats"];
};

const Dialog = ({
  incompleteCardInfos,
  onlyOne,
  onCancel,
  onConfirm,
}: {
  incompleteCardInfos: CardInfo[];
  onlyOne: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const sourceCardInfos = incompleteCardInfos.map(({id, stats}) => ({
    card: api.getModel({modelName: "card", id}),
    stats,
  }));
  const firstInfo = incompleteCardInfos[0];
  return (
    <Col sp="16px">
      {onlyOne && firstInfo ? (
        <Text type="prose14" color="primary">
          Only <b>{firstInfo.stats.checked}</b> out of <b>{firstInfo.stats.total}</b> checkboxes are
          checked
        </Text>
      ) : (
        sourceCardInfos.map(({card, stats}) => (
          <Row align="center" sp="4px">
            <Text type="prose14" color="primary">
              <b>{stats.checked}</b> out of <b>{stats.total}</b> are checked in
            </Text>
            <CardLink card={card} inline onClick={onCancel} />{" "}
          </Row>
        ))
      )}

      <Row sp="16px" align="center">
        <DSButton variant="secondary" onClick={onCancel}>
          Cancel
        </DSButton>
        <DSButton variant="primary" size="lg" onClick={onConfirm} className={css({ml: "auto"})}>
          Ignore checkboxes and mark Card{incompleteCardInfos.length === 1 ? "" : "s"} as done
        </DSButton>
      </Row>
    </Col>
  );
};

export const performDoneAction = async ({
  cardIds,
  onCardDone,
}: {
  cardIds: CardId[];
  onCardDone?: () => void;
}) => {
  const {incompleteCardInfos} = await checkCards(cardIds);
  if (incompleteCardInfos.length > 0) {
    const ok = await confirm({
      title:
        incompleteCardInfos.length === 1
          ? "This Card has unchecked list items"
          : "Cards have unchecked list items",
      comp: Dialog,
      compProps: {incompleteCardInfos, onlyOne: cardIds.length === 1},
    });
    if (!ok) return false;
  }
  if (onCardDone) await onCardDone();
  await api.mutate.cards.bulkUpdate({ids: cardIds, status: "done"});
  return true;
};
