import {api} from "../../../lib/api";
import {pronounceSafeSeq} from "../../../lib/sequences";
import {shrinker} from "@cdx/common";
import {isHeroCard} from "../../workflows/workflow-utils";

export const referencePattern = new RegExp(`\\$([${pronounceSafeSeq.letters}]{3,5})`);

const typeInfo = {
  isHero: {
    cardSpecific: false,
    toConstraint: () => ({
      $or: [
        // either has child cards
        {childCards: {op: "notIn", value: [null, "{}"]}},
        // or has a deck, but none that allows
        {
          $and: [{"!deck": {allowedCardTypes: {op: "has", value: ["task"]}}}, {deck: {}}],
        },
      ],
    }),
    label: "Is Hero Card",
    searchLabel: () => `Hero`,
    query: "hero",
  },
  isSub: {
    cardSpecific: false,
    toConstraint: () => ({parentCardId: {op: "neq", value: null}}),
    label: "Is Sub Card",
    searchLabel: () => `Sub`,
    query: "subcard",
  },
  childCards: {
    cardSpecific: true,
    toConstraint: (id) => ({parentCardId: id}),
    label: "Show Sub Cards",
    searchLabel: (title) => `sub cards: ${title}`,
  },
  isLocked: {
    cardSpecific: false,
    toConstraint: () => ({hasBlockingDeps: true}),
    label: "Is locked",
    searchLabel: () => `Locked`,
    query: "locked",
  },
  isNotLocked: {
    cardSpecific: false,
    toConstraint: () => ({hasBlockingDeps: false}),
    label: "Is not locked",
    searchLabel: () => `Not locked`,
    query: "locked",
  },
  withCoverImag: {
    cardSpecific: false,
    toConstraint: () => ({coverFileId: {op: "neq", value: null}}),
    label: "With cover image",
    searchLabel: () => `Cover`,
    query: "coverImage",
  },
  isPrivate: {
    cardSpecific: false,
    toConstraint: () => ({deckId: null}),
    label: "Private Cards",
    searchLabel: () => `Private`,
    query: "private",
  },
  isDoc: {
    cardSpecific: false,
    toConstraint: () => ({isDoc: true}),
    label: "Doc Cards",
    searchLabel: () => `Doc`,
    query: "doc",
  },
  isBeast: {
    cardSpecific: false,
    toConstraint: () => ({
      meta: {op: "jsonSearch", field: "sprintLockedIn.level", jsonOp: "gt", value: 0},
    }),
    label: "Beast Card",
    searchLabel: () => `Beast`,
    query: "beast",
  },
};

const nonCardSpecs = Object.entries(typeInfo).filter(([, t]) => !t.cardSpecific);

const cardCategories = [
  {
    key: "card",
    label: "Card",
    getSuggestions({input, root}) {
      const match = input.match(referencePattern);
      if (match) {
        const accountSeq = pronounceSafeSeq.seqToInt(match[1]);
        const card = root.account.$meta.find("cards", {accountSeq})[0] || null;
        if (card && isHeroCard(card)) {
          return [{id: card.id, type: "childCards"}];
        }
      } else {
        return nonCardSpecs
          .filter(([, {query}]) => query.indexOf(input) === 0)
          .map(([key]) => ({type: key}));
      }
    },
    valueToKey({id, type}) {
      return typeInfo[type].cardSpecific ? `${id}-${type}` : type;
    },
    valuesToDbQuery(values) {
      return values.reduce((m, {id, type}) => ({...m, ...typeInfo[type].toConstraint(id)}), {});
    },
    renderSuggestion({type}) {
      return typeInfo[type].label;
    },
    renderPill({id, type}) {
      const {searchLabel, cardSpecific} = typeInfo[type];
      if (cardSpecific) {
        const card = api.getModel({modelName: "card", id});
        return searchLabel(card?.title);
      } else {
        return searchLabel();
      }
    },
    savedSearchLabel({id, type}) {
      const {searchLabel, cardSpecific} = typeInfo[type];
      const title = cardSpecific
        ? shrinker(api.getModel({modelName: "card", id})?.title, 20)
        : null;
      return {prio: 2, label: searchLabel(title)};
    },
  },
];

export default cardCategories;
