import {pillStyles} from "./deck.css";
import {cx, TooltipForChild} from "@cdx/common";

type PillProps = {
  children: React.ReactNode;
  type: keyof typeof pillStyles.container.byType;
  hasNotifications?: boolean;
  size?: keyof typeof pillStyles.container.bySize & keyof typeof pillStyles.iconArea.bySize;
  tooltip?: string;
  style?: React.CSSProperties;
  className?: string;
  bigNumber?: boolean;
};

const Pill = ({
  children,
  type,
  hasNotifications,
  size = "md",
  tooltip,
  style,
  className,
  bigNumber,
}: PillProps) => (
  <TooltipForChild tooltip={tooltip} delayed bg="gray800">
    <div
      className={cx(
        className,
        pillStyles.container.base,
        pillStyles.container.bySize[size],
        pillStyles.container.byType[type]
      )}
      style={style}
    >
      <div
        className={cx(
          pillStyles.content.base,
          pillStyles.content.bySize[size],
          bigNumber && pillStyles.content.bigNumber
        )}
      >
        {children}
      </div>
      <div
        className={cx(
          pillStyles.iconArea.base,
          pillStyles.iconArea.bySize[size],
          hasNotifications && pillStyles.iconArea.withNotification
        )}
      />
    </div>
  </TooltipForChild>
);

export default Pill;
