import {hash as md5} from "spark-md5";

export const EXPERIMENTS = {
  ExpandOnboarding: `2023:onboarding:expand`,
  NotifcationsWithFaces: `2023:notifications:avatars`,
};

export const experimentStarts = {};

const isActive = (key: string, id: string) => {
  const hash = md5(`${key}${id}`);
  return parseInt(hash[hash.length - 1], 16) % 2 === 1;
};

export const useExperiment = (key: string, account: any) => {
  if (!key) throw new Error(`No such experiment found: '${key}'`);
  if (process.env.REACT_APP_IS_STAGE === "true") return true;
  if (process.env.NODE_ENV === "development") return true;
  const id = account?.$meta.get("id", null);
  if (!id) return false;
  if (id === "14c3021c-95ad-11e9-b939-5368e19a8f5e") return true; // mmensch is always active
  return isActive(key, id);
};
