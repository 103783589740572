import {Card} from "../../cdx-models/Card";
import {File} from "../../cdx-models/File";
import {Root} from "../../cdx-models/Root";

const getCoverType = (
  passedMeta: File["meta"],
  cardMeta: Card["meta"]
): "white" | "gray" | "dark" => {
  const meta = passedMeta as any;
  const explicitVal = cardMeta?.coverFileModifier?.mode;
  if (explicitVal) return explicitVal === "dark" ? "gray" : "white";
  const h = meta && meta.luminanceHisto;
  const s = meta && meta.sharpness;
  if (!h) return "gray";
  const lightAreaScore = h[9] + h[8] * 0.15 + h[7] * 0.05;
  if (lightAreaScore > 300) return "white";
  if (s[0] > 70) return "gray";
  return lightAreaScore < 20 ? "dark" : "gray";
};

export const getCoverFileWithParent = (root: Root, card: Card, parentCard: Card | null) => {
  if (root.account.allowInheritHeroCover) {
    if (parentCard && parentCard.coverFile) {
      return {
        coverFile: parentCard.coverFile,
        coverFileCardMeta: parentCard.meta,
        cardId: parentCard.cardId,
      };
    }
    if (card.coverFile) {
      return {coverFile: card.coverFile, coverFileCardMeta: card.meta, cardId: card.cardId};
    }
  } else {
    if (card.coverFile) {
      return {coverFile: card.coverFile, coverFileCardMeta: card.meta, cardId: card.cardId};
    }
    if (parentCard && parentCard.coverFile) {
      return {
        cardId: parentCard.cardId,
        coverFile: parentCard.coverFile,
        coverFileCardMeta: parentCard.meta,
      };
    }
  }
  return {coverFile: null, coverFileCardMeta: null, cardId: null};
};

export type CoverInfo = {
  url: string;
  type: "gray" | "white" | "dark";
  modifier: null | {
    offsetX: number;
    offsetY: number;
    bigCardOffsetX?: number;
    bigCardOffsetY?: number;
    mode: null | "dark" | "light";
    noBlur: boolean;
  };
};

export const getCoverInfoWithParent = (
  root: Root,
  card: Card,
  parentCard: Card | null
): CoverInfo | null => {
  const {coverFile, coverFileCardMeta} = getCoverFileWithParent(root, card, parentCard);
  if (!coverFile) return null;
  const coverFileUrl = coverFile && coverFile.$meta.get("url", null);
  const coverFileMeta = coverFile && coverFile.$meta.get("meta", null);
  if (!coverFileUrl || !coverFileMeta) return null;
  const coverType = getCoverType(coverFileMeta, coverFileCardMeta);
  return {
    url: coverFileUrl,
    type: coverType,
    modifier: coverFileCardMeta?.coverFileModifier ?? null,
  };
};
