import {Location, History} from "history";
import {useOverlayScrollbars} from "overlayscrollbars-react";
import useMutation from "../../lib/hooks/useMutation";
import {Box, Col, DSIconButton, DSIconPlay, DSIconTrash, DsButtonAction, Row, css} from "@cdx/ds";
import {ToggleButton, RevealSpinner, useEsc, isMac} from "@cdx/common";
import {PersistStore} from "../../lib/hooks/usePersistStore";
import {ReactNode, forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {isSelectionActive} from "../../lib/utils";
import {CardContentForm} from "./CardContentForm";
import {PreviewCardContent as RawPreviewCardContent} from "./PreviewCardContent";
import {makeScrollable} from "@cdx/ds/utils/makeScrollable";
import Markdown from "../../components/Markdown";
import getSelectedProjects, {getRawSelectedProjects} from "../../lib/hooks/useSelectedProjects";
import {useStickyBox} from "react-sticky-box";
import {getVarName} from "@vanilla-extract/private";
import {cardContainerVars} from "./unified-card-container.css";
import {CdxCropImgByFile} from "../../components/CdxImg";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import {
  SelectContent,
  lexicalSerializer,
  useCdxEditorState,
} from "../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import {mergeRefs} from "react-merge-refs";
import {Card, CardId} from "../../cdx-models/Card";
import {Project, ProjectId} from "../../cdx-models/Project";
import {Root} from "../../cdx-models/Root";
import {Deck} from "../../cdx-models/Deck";
import {SprintConfigId} from "../../cdx-models/SprintConfig";
import {MilestoneId} from "../../cdx-models/Milestone";
import {Notifications} from "../notifications/useNotifications";
import {CoverInfo} from "../../components/Card/card-cover-utils";
import {WorkflowItem} from "../../cdx-models/WorkflowItem";

const PreviewCardContent = RawPreviewCardContent as any;

export const openSidePanel = (
  history: History,
  location: Location,
  sidePanelKey: string | null,
  active?: boolean
) => {
  history.replace({
    ...location,
    state: {...(location.state as {}), sidePanelKey: active ? null : sidePanelKey},
  });
};

export const useDismissNotificationHandler = (opts: {
  root: Root;
  card: Card;
  notifications?: Notifications;
}) => {
  const {root, card, notifications} = opts;
  const [doDismissCardDiffs] = useMutation("notifications", "dismissCardDiffs");
  if (!notifications) return () => {};
  const cardId = card.$meta.get("cardId", null) as CardId;
  const hasCardDiffs = Boolean(notifications?.perCardDiffs[cardId]);
  const meId = root.loggedInUser?.id;

  return () => {
    if (!hasCardDiffs || !meId) return null;
    return doDismissCardDiffs({cardIds: [cardId], userId: meId});
  };
};

export const AddAnotherItem = (props: {
  label: string;
  card: Card;
  active: boolean;
  onChange: () => void;
}) => {
  const {label, card, active, onChange} = props;
  return (
    <Row
      as="label"
      sp="4px"
      align="center"
      justify="end"
      px="8px"
      pt="4px"
      pb={card.assignee && !card.masterTags.length ? "32px" : "4px"}
    >
      <Box textTransform="uppercase" bold color="secondary" size={11}>
        Create another {label} after this one
      </Box>
      <ToggleButton checked={active} onChange={onChange} />
    </Row>
  );
};

type ViewContentProps = {
  card: Card;
  canModify: boolean | null;
  onOpenEditPanel: (maybeContentEvent?: any) => void;
  onContentClick: (e: any) => void;
  onSaveContent: (content: string) => unknown;
  project?: Project;
  persistStore: PersistStore<Card>;
  aboveContent?: ReactNode;
  belowContent?: ReactNode;
  bottom?: ReactNode;
  noContentFallback?: ReactNode;
  coverInfo?: CoverInfo | null;
};
export const ViewCardContainerContent = (props: ViewContentProps) => {
  const {
    card,
    canModify,
    onOpenEditPanel,
    onContentClick,
    project,
    persistStore,
    onSaveContent,
    coverInfo,
  } = props;
  const {aboveContent, belowContent, bottom, noContentFallback} = props;
  const [skipClick, setSkipClick] = useState(false);
  const getKey = () => {
    if (card.$meta.modelName === "card") {
      return card.$meta.get("cardId", null);
    } else {
      const item = card as unknown as WorkflowItem;
      return item.$meta.get("itemId", null);
    }
  };

  const handleMouseDown = () => {
    if (isSelectionActive()) {
      setSkipClick(true);
    }
  };
  const handleMouseUp = () => {
    setSkipClick(false);
  };
  const handleClick = (e: any) => {
    if (canModify && !skipClick) onContentClick(e);
    setSkipClick(false);
    e.stopPropagation();
  };

  const contentLoaded = card.$meta.get("content", false) !== false;
  const isUnsaved = persistStore.useHasStoredValues(card);

  const scrollNodeRef = useRef<HTMLDivElement>(null);
  const [initialize] = useOverlayScrollbars({
    options: {
      scrollbars: {dragScroll: true},
    },
    defer: true,
  });

  useEffect(() => {
    if (!isMac()) initialize(scrollNodeRef.current!);
  }, [initialize]);

  return (
    <Col relative flex="auto" minHeight="0" pointerEvents="none">
      <div
        ref={scrollNodeRef}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        key={getKey()}
        className={css(
          {
            display: "flex",
            flexDir: "column",
            flex: "auto",
            position: "relative",
            overscrollBehaviour: "contain",
          },
          makeScrollable()
        )}
      >
        {/*
          useOverlayScrollbars does not like if we remove or add direct children after initialization.
          We therefeo add a dummy div here
        */}
        <Col flex="auto">
          {coverInfo && (
            <Box width="100%" flex="none" style={{aspectRatio: "2.5", marginBottom: -42}} />
          )}
          <Col flex="auto" sp={coverInfo ? "8px" : undefined} pointerEvents="auto">
            {isUnsaved && (
              <UnsavedChanges
                onOpenEditPanel={onOpenEditPanel}
                onClearLs={() => persistStore.clearStoredValue(card)}
              />
            )}
            {aboveContent}
            {contentLoaded &&
              (card.content ? (
                <Markdown
                  projectId={project?.id}
                  size="lg"
                  parseCheckboxes={process.env.REACT_APP_MODE === "open" ? "disabled" : true}
                  onCheckboxClick={(v: string) => v && onSaveContent(v)}
                  style={{
                    padding: "1rem 1rem 4rem",
                    flex: "auto",
                    ...(coverInfo && {
                      backgroundColor: "rgba(255 255 255 / 90%)",
                      borderRadius: 4,
                      backdropFilter: "blur(10px)",
                    }),
                  }}
                  autoTitle
                  imageViewerKey={`content-${card.cardId}`}
                >
                  {card.content}
                </Markdown>
              ) : (
                noContentFallback
              ))}
            {belowContent}
          </Col>
        </Col>
      </div>
      <RevealSpinner show={!contentLoaded} withCover />
      {bottom}
    </Col>
  );
};

type EditContentProps = {
  card: Card;
  onClose: () => void;
  selectContent?: SelectContent | null;
  persistStore: PersistStore<Card>;
  contentKey: string | false | null;
  onSaveContent: (text: string) => Promise<unknown>;
  onCardChange?: (opts: any) => unknown;
  focusProps?: any;
  onReceiveFile?: (opts: any) => unknown;
  project?: Project;
  allowEmpty?: boolean;
};
export const EditCardContainerContent = (props: EditContentProps) => {
  const {
    card,
    onClose,
    selectContent,
    contentKey,
    persistStore,
    onSaveContent,
    onCardChange,
    focusProps,
    onReceiveFile,
    project,
    allowEmpty,
  } = props;
  useEsc(onClose);
  const [isEmpty, setIsEmpty] = useState(false);
  const contentField = useCdxEditorState({
    initialContent: () =>
      lexicalSerializer.fromJSON(persistStore.getStoredValue(card)) || {
        type: "text",
        value: card.$meta.get("content", false),
        selectContent,
      },
    contentKey: contentKey,
    onChangeListeners: {
      isEmpty: setIsEmpty,
    },
  });

  persistStore.usePersistValues(card, () => {
    const text = !isEmpty && (contentField.getText() || "").trim();
    if (text && text !== card.content.trim()) {
      return lexicalSerializer.toJSON(contentField);
    }
  });

  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = () => setShowPreview((v) => !v);

  const handleContentSave = () =>
    onSaveContent(contentField.getText()).then(() => {
      persistStore.clearStoredValue(card);
      onClose();
    });
  const handleCancel = () => {
    persistStore.clearStoredValue(card);
    onClose();
  };
  return showPreview ? (
    <PreviewCardContent
      contentAsText={contentField.getText()}
      togglePreview={togglePreview}
      handleSubmit={handleContentSave}
      buttonLabel="Save"
      project={project || card.deck?.project}
    />
  ) : (
    <CardContentForm
      card={card}
      project={project || card.deck?.project}
      contentField={contentField}
      togglePreview={togglePreview}
      isEmpty={isEmpty}
      handleSubmit={handleContentSave}
      buttonLabel="Save"
      onCancel={handleCancel}
      onCardChange={onCardChange}
      focusProps={focusProps}
      onReceiveFile={onReceiveFile}
      allowEmpty={allowEmpty}
    />
  );
};

type UnsavedChangesProps = {
  onOpenEditPanel: () => void;
  onClearLs: () => void;
};
export const UnsavedChanges = ({onOpenEditPanel, onClearLs}: UnsavedChangesProps) => (
  <Box px="12px" pt="8px">
    <Col
      colorTheme="purple350"
      bg="foreground"
      px="12px"
      py="8px"
      color="primary"
      sp="12px"
      rounded={4}
    >
      <Box bold size={14} textAlign="center">
        This card contains unsaved content changes
      </Box>
      <Row sp="4px" justify="center">
        <DSIconButton
          size="sm"
          variant="primary"
          icon={<DSIconPlay />}
          onClick={(e) => {
            onOpenEditPanel();
            e.stopPropagation();
          }}
          label="Continue Editing"
        />
        <DSIconButton
          size="sm"
          variant="primary"
          icon={<DSIconTrash />}
          label="Discard Changes"
          onClick={(e) => {
            onClearLs();
            e.stopPropagation();
          }}
        />
      </Row>
    </Col>
  </Box>
);

// if card in a milestone or sprint, return the projects that are allowed to access the card
export const getCardProjectIfRestricted = ({card, root}: {card: Card; root: Root}) => {
  const getMsProjects = (): Project[] => {
    const ms = card.milestone;
    if (!ms || ms.$meta.get("isGlobal", true)) return [];
    return ms.$meta
      .find("milestoneProjects", {project: {visibility: "default"}})
      .map((mp) => mp.project)
      .filter(Boolean); // sometimes project is null, if not accessible
  };
  const getSprintProjects = (): Project[] => {
    const sprint = card.sprint;
    if (!sprint || sprint.sprintConfig.$meta.get("isGlobal", true)) return [];
    return sprint.sprintConfig.$meta
      .find("sprintProjects", {project: {visibility: "default"}})
      .map((sp) => sp.project)
      .filter(Boolean); // sometimes project is null, if not accessible
  };
  const msProjects = getMsProjects();
  const sprintProjects = getSprintProjects();
  let selProjects = getSelectedProjects(root);
  const limitProjectIdSet = new Set(
    [...msProjects, ...sprintProjects]
      .map((p) => p.$meta.get("id", null) as ProjectId)
      .filter(Boolean)
  );
  if (limitProjectIdSet.size) {
    selProjects = selProjects.filter((p) =>
      limitProjectIdSet.has(p.$meta.get("id", null) as ProjectId)
    );
  }
  return selProjects;
};

export const getBulkCardProjectIfRestricted = ({cards, root}: {cards: Card[]; root: Root}) => {
  const msSet = new Set<MilestoneId | null>();
  const sprintConfigSet = new Set<SprintConfigId | null>();
  for (const card of cards) {
    if (card.milestone && !card.milestone.isGlobal) {
      msSet.add(card.milestone.$meta.get("id", null));
    }
    if (card.sprint && !card.sprint.sprintConfig.isGlobal) {
      sprintConfigSet.add(card.sprint.sprintConfig.$meta.get("id", null));
    }
  }
  const msIds = [...msSet].filter(Boolean) as MilestoneId[];
  const sprintConfigIds = [...sprintConfigSet].filter(Boolean) as SprintConfigId[];
  const rawSelProjects = getRawSelectedProjects(root);
  const rawSelProjectIds = rawSelProjects
    .map((p) => p.$meta.get("id", null) as ProjectId)
    .filter(Boolean);
  const projects = root.account.$meta.find("projects", {
    ...(rawSelProjectIds.length ? {id: rawSelProjectIds} : {}),
    $and: [
      ...msIds.map((id) => ({milestoneProjects: {milestoneId: id}})),
      ...sprintConfigIds.map((id) => ({sprintProjects: {sprintConfigId: id}})),
    ],
  });
  return projects;
};

export const StickyTopAwareStickyBox = forwardRef((props: any, ref) => {
  const [stickyTopVarValue, setStickyTopVarValue] = useState<number | null>(null);
  const nodeRef = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    const n = nodeRef.current;
    if (!n) return;
    const val = getComputedStyle(n).getPropertyValue(getVarName(cardContainerVars.stickyTop));
    setStickyTopVarValue(val ? parseInt(val, 10) : 0);
  }, []);

  const stickyRef = useStickyBox({offsetTop: stickyTopVarValue || 0});
  const usedRef = stickyTopVarValue === null ? nodeRef : stickyRef;
  const refs = useMemo(() => (ref ? mergeRefs([usedRef, ref]) : usedRef), [ref, usedRef]);

  return <div {...props} ref={refs} />;
});

type MiniDeckButtonProps = {
  deck: Deck;
  maxChars: number;
  badDeck?: boolean;
  buttonProps: DsButtonAction;
};

export const MiniDeckButton = forwardRef<HTMLElement, MiniDeckButtonProps>((props, ref) => {
  const {deck, maxChars, badDeck, buttonProps, ...rest} = props;
  if (!deck || deck.$meta.isDeleted()) {
    if (process.env.REACT_APP_MODE === "open") {
      return (
        <Box size={14} color="secondary">
          Some Deck
        </Box>
      );
    } else {
      return (
        <Box size={14} color="secondary" textDecoration="line-through">
          Deleted Deck
        </Box>
      );
    }
  } else {
    const image = (
      <CdxCropImgByFile
        width={16}
        height={16}
        file={deck.coverFile}
        className={css({
          flex: "none",
          elevation: 100,
          rounded: 4,
          overflow: "hidden",
        })}
        fallbackClassName={css({colorTheme: "gray500", bg: "foreground"})}
        style={{margin: -1}}
      />
    );
    const label = (
      <UnMarkedown maxChars={maxChars} projectId={deck.project.id}>
        {deck.title}
      </UnMarkedown>
    );
    return (
      <DSIconButton
        variant="secondary"
        size="sm"
        sp="8px"
        icon={image}
        label={label as unknown as string}
        active={badDeck}
        {...buttonProps}
        {...rest}
        ref={ref}
      />
    );
  }
});
