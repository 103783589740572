import {Box, Col, css, DSButton, DSIconButton, DSIconPencil, Row, StyleProps, Text} from "@cdx/ds";
import {ResolvableId} from "../../../../cdx-models/Resolvable";
import {ResolvableEntry, ResolvableEntryId} from "../../../../cdx-models/ResolvableEntry";
import Markdown from "../../../../components/Markdown";
import useMutation from "../../../../lib/hooks/useMutation";
import FromNow, {useAgeColorProps} from "../../../../components/FromNow";
import {User, UserId} from "../../../../cdx-models/User";
import {MutableRefObject, ReactNode, useEffect, useRef, useState} from "react";
import {convoStyles as styles} from "./convo.css";
import {TooltipForChild, cx, useDelayedTrigger} from "@cdx/common";
import uiClasses from "@cdx/common/xui/ui.css";
import {CommentEditForm} from "./CommentForm";
import {ConvoAvatar, ResolvableBag, getConvoUserName} from "./convo-utils";
import {getModalUrl} from "../../../../components/ModalRegistry";
import {checkIfPresent} from "../../../../lib/mate/mate-utils";
import {api} from "../../../../lib/api";
import {Root} from "../../../../cdx-models/Root";
import {dsColors} from "@cdx/ds/css/tokens";

export const ConvoLine = ({isDashed}: {isDashed?: boolean}) => (
  <div className={cx(styles.line.base, isDashed && styles.line.dashed)} />
);

export const CommentLikeContainer = (props: {
  isFirst?: boolean;
  user: User | null;
  dimUser?: boolean;
  children: ReactNode;
  className?: string;
  resBag: ResolvableBag;
  pt: StyleProps["pt"];
  lineType: "none" | "entry" | "skip";
}) => {
  const {children, lineType, className, user, dimUser, resBag, isFirst, pt} = props;
  // const {isOpen, setIsOpen} = resBag;
  return (
    <Row sp="12px" className={className}>
      <Col className={styles.setAccentAsBorder} relative width="24px" flex="none" sp="6px">
        {isFirst && <div style={{height: 36}} />}
        <Col relative flex="auto">
          <Box absolute zIndex={1} pt={pt} left="0" top="0">
            <ConvoAvatar
              resBag={resBag}
              user={user}
              size={24}
              flat
              className={dimUser ? styles.avatar.done : undefined}
            />
          </Box>
          {lineType !== "none" && <ConvoLine isDashed={lineType === "skip"} />}
        </Col>
      </Col>
      <Col sp="8px" pb={lineType === "none" ? 0 : "12px"} flex="auto" minWidth="0">
        {children}
      </Col>
    </Row>
  );
};

const markedAsSeenSet = new Set<ResolvableEntryId>();

type MarkAsSeenProps = {
  nodeRef: MutableRefObject<HTMLElement | null>;
  entryId: ResolvableEntryId;
  resolvableId: ResolvableId;
  root: Root;
  onSeen: (entryId: ResolvableEntryId) => unknown;
};

const MarkAsSeen = ({nodeRef, entryId, root, resolvableId, onSeen}: MarkAsSeenProps) => {
  const userId = root.loggedInUser?.id as UserId;
  const trigger = useDelayedTrigger();
  const onSeenRef = useRef(onSeen);
  onSeenRef.current = onSeen;

  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;
    const handleSeen = () => {
      if (markedAsSeenSet.has(entryId)) return;
      markedAsSeenSet.add(entryId);
      return onSeenRef.current(entryId);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.rootBounds &&
            entry.rootBounds.height < entry.boundingClientRect.height &&
            entry.intersectionRatio > 0
          ) {
            // comment is larger than viewport, so intersection ratio will always be < 1
            trigger.fire(() => handleSeen(), 2000);
          } else if (entry.isIntersecting) {
            trigger.fire(() => handleSeen(), 2000);
          } else {
            trigger.cancel();
          }
        });
      },
      {
        root: null,
        rootMargin: "72px",
        threshold: 0.9,
      }
    );

    observer.observe(node);
    return () => observer.disconnect();
  }, [nodeRef, trigger, entryId, resolvableId, userId]);
  return null;
};

type CommentProps = {
  header?: ReactNode;
  entry: ResolvableEntry;
  isPreview?: boolean;
  resBag: ResolvableBag;
  isTarget?: boolean;
  isUnseen?: boolean;
  isLast?: boolean;
  lineType?: "none" | "entry" | "skip";
  onSeen: null | ((entryId: ResolvableEntryId) => void);
};
const Comment = (props: CommentProps) => {
  const {entry, isPreview, resBag, isTarget, isUnseen, isLast, header, lineType, onSeen} = props;
  const {root, card, onReceiveFile, resolvable} = resBag;
  const [doUpdate] = useMutation("resolvables", "updateComment");
  const [isEditing, setIsEditing] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const {isLoaded} = checkIfPresent(() => entry.content, api);
  const shouldScroll = isTarget && isLoaded;
  const bagRef = useRef(resBag);
  useEffect(() => {
    bagRef.current = resBag;
  });
  useEffect(() => {
    if (shouldScroll && nodeRef.current) {
      nodeRef.current.scrollIntoView({behavior: "smooth", block: "center"});
      bagRef.current.onScrollToTarget();
    }
  }, [shouldScroll]);

  const handleCheckboxClick = (newContent: string) =>
    doUpdate({entryId: entry.entryId, content: newContent, resolvableId: resolvable.id});

  const mayEdit = root.loggedInUser === entry.author && !resolvable.isClosed;
  const ageProps = useAgeColorProps(entry.createdAt);

  return (
    <CommentLikeContainer
      user={entry.author}
      lineType={lineType || (isPreview ? "skip" : "entry")}
      className={uiClasses.hideContainer}
      resBag={resBag}
      isFirst={Boolean(header)}
      pt={header ? 0 : "4px"}
    >
      <Col
        colorTheme={isUnseen ? "active25" : "gray25"}
        bg="foreground"
        sp="4px"
        pa="4px"
        rounded={isPreview ? undefined : 12}
        roundedTop={isPreview ? 12 : undefined}
        relative
        className={cx(styles.setAccentAsBorder, isPreview ? styles.previewComment : undefined)}
        ref={nodeRef}
        {...(header
          ? {borderWidth: 2, borderColor: "default", borderBottomWidth: isPreview ? 0 : undefined}
          : {})}
      >
        {isLast && isUnseen && onSeen && (
          <MarkAsSeen
            nodeRef={nodeRef}
            entryId={entry.entryId as ResolvableEntryId}
            resolvableId={resolvable.id as ResolvableId}
            root={root}
            onSeen={onSeen}
          />
        )}
        {header}
        <Col sp="4px" px="12px" py="4px">
          <Row sp="8px" wrap align="center" relative>
            <Text type="label14" color="primary">
              {getConvoUserName(entry.author, resBag)}
            </Text>
            <Text type="label12light" {...ageProps}>
              <FromNow date={entry.createdAt} />
            </Text>
            {mayEdit && !isEditing && (
              <Box ml="auto">
                <DSIconButton
                  icon={<DSIconPencil />}
                  size="sm"
                  variant="tertiary"
                  negatePadding
                  onClick={() => setIsEditing(true)}
                  label="Edit"
                  className={uiClasses.hideElement}
                />
              </Box>
            )}
          </Row>
          {isEditing ? (
            <CommentEditForm
              entry={entry}
              resolvable={resolvable}
              closeEdit={() => setIsEditing(false)}
              card={card}
              onReceiveFile={onReceiveFile}
            />
          ) : (
            <Col sp="8px">
              <Markdown
                projectId={card.deck && card.deck.project.id}
                parseCheckboxes={
                  process.env.REACT_APP_MODE !== "open" && entry.author.id === root.loggedInUser?.id
                    ? true
                    : "disabled"
                }
                onCheckboxClick={handleCheckboxClick}
                imageViewerKey={`comment-${resolvable.id}`}
                imageViewerImgKeyPrefix={`e-${entry.entryId}-`}
                variant="default"
                className={styles.comment}
              >
                {entry.content}
              </Markdown>
              {isPreview && <div className={styles.previewFade} />}
              {!isPreview && entry.version > 1 ? (
                <Col align="start">
                  <TooltipForChild
                    tooltip={
                      <Box px="12px" py="8px">
                        This comment was last edited{" "}
                        <b>
                          <FromNow date={entry.lastChangedAt} />
                        </b>
                        .<br />
                        Click to see history.
                      </Box>
                    }
                  >
                    <DSButton
                      variant="tertiary"
                      size="sm"
                      negatePadding
                      to={getModalUrl({
                        location: resBag.location,
                        modal: `commentHistory.${entry.entryId}`,
                        state: null,
                      })}
                      style={{color: dsColors.gray300, fontWeight: "normal"}}
                      className={css({pt: "4px"})}
                    >
                      Edited <FromNow date={entry.lastChangedAt} />
                    </DSButton>
                  </TooltipForChild>
                </Col>
              ) : null}
            </Col>
          )}
        </Col>
      </Col>
    </CommentLikeContainer>
  );
};

export default Comment;
