import {useLexicalNodeSelection} from "@lexical/react/useLexicalNodeSelection";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect, useRef} from "react";
import {mergeRegister} from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from "lexical";
import {DSTextEditorStyles} from "./DSTextEditor.css";

const useLexicalDecoratorNode = (nodeKey: string) => {
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();

  const refObj = {isSelected, setSelected, nodeKey, clearSelection};
  const refs = useRef(refObj);
  useEffect(() => {
    refs.current = refObj;
  });

  useEffect(() => {
    const onDelete = (payload: KeyboardEvent) => {
      if (refs.current.isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(refs.current.nodeKey);
        if (node) node.remove();
        refs.current.setSelected(false);
      }
      return false;
    };
    return mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        (event: MouseEvent) => {
          const el = editor.getElementByKey(refs.current.nodeKey);

          if (el?.contains(event.target as Node)) {
            if (!event.shiftKey) {
              refs.current.clearSelection();
            }
            refs.current.setSelected(!refs.current.isSelected);
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
    );
  }, [editor]);

  return {
    selectionClassName: isSelected ? DSTextEditorStyles.blockSelected : undefined,
    editor,
  };
};

export default useLexicalDecoratorNode;
