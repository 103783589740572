import {forwardRef} from "react";
import {emojiStyles} from "./emoji.css";
import {cx} from "@cdx/common";
import cdxEnv from "../../env";

// based on https://github.com/twitter/twemoji/blob/9a609d1bb02f48d3edb3bcee2f613ae29ec742e5/scripts/build.js
function toCodePoint(unicodeSurrogates, sep) {
  const r = [];
  let c = 0;
  let p = 0;
  let i = 0;
  while (i < unicodeSurrogates.length) {
    c = unicodeSurrogates.charCodeAt(i++);
    if (p) {
      r.push((0x10000 + ((p - 0xd800) << 10) + (c - 0xdc00)).toString(16));
      p = 0;
    } else if (0xd800 <= c && c <= 0xdbff) {
      p = c;
    } else {
      r.push(c.toString(16));
    }
  }
  return r.join(sep);
}

const UFE0Fg = /\uFE0F/g;
const U200D = String.fromCharCode(0x200d);

const processEmoji = (input, sep) =>
  input && toCodePoint(input.indexOf(U200D) < 0 ? input.replace(UFE0Fg, "") : input, sep);

const EMOJI_BASE_PATH = `${cdxEnv.CDN_HOST}/twemoji/v14.0.2/72x72/`;

/** @type {React.ForwardRefExoticComponent<{decoratedText?: string, children?: string, block?: boolean, className?: string} & React.RefAttributes<HTMLSpanElement>>} */
const Emoji = forwardRef(({decoratedText, children, block, className}, ref) => {
  const code = processEmoji(decoratedText || children, "-");
  const backgroundImage = code && `url(${EMOJI_BASE_PATH}${code}.png)`;
  return (
    <span
      className={cx(emojiStyles.base, emojiStyles[block ? "block" : "inline"], className)}
      style={{backgroundImage}}
      ref={ref}
    >
      {children || decoratedText}
    </span>
  );
});

export default Emoji;
