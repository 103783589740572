import {
  Box,
  Col,
  css,
  DSIconChevronDown,
  DSIconGhost,
  DSRawButton,
  Row,
  useReactiveIcon,
} from "@cdx/ds";
import {ArrowOverlay, TooltipForChild, useGlobalKeyPress, useNextDropDown} from "@cdx/common";
import {ReactNode, forwardRef, useMemo} from "react";
import {CdxCropImgByFile} from "../../components/CdxImg";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import {Root} from "../../cdx-models/Root";
import {Deck, DeckId} from "../../cdx-models/Deck";
import {mergeRefs} from "react-merge-refs";
import {DeckListPicker} from "../../components/RichTextarea/Lexical/CardPropChangeOverlay";
import {Project} from "../../cdx-models/Project";

type AllowSetNone = {label: string; icon: ReactNode};

export const DeckPickerImage = ({deck, className}: {deck?: Deck; className?: string}) => (
  <CdxCropImgByFile
    width={16}
    height={16}
    file={deck?.coverFile}
    fallbackClassName={css({bg: "foreground"}, colorThemes.gray500)}
    className={css({flex: "none", elevation: 100, rounded: 4, overflow: "hidden"}, className)}
  />
);

type DSDeckPickerButtonProps = {
  deck?: Deck;
  deckId?: DeckId | null;
  maxChars: number;
  badDeck?: boolean; // if arenaCtx is of a deck, but this deck is a different one
  canModify?: boolean | null;
  root: Root;
  onPickDeckId: (deckId: DeckId | null) => unknown;
  allowSetNone?: AllowSetNone;
  category: string;
  noDeckLabel?: string;
  noDeckIcon?: ReactNode;
  getProjects: () => Project[];
  renderOverlay?: (opts: {overlayProps: any; close: () => void}) => ReactNode;
  filterFn?: (deck: Deck) => boolean;
};

export const DSDeckPickerButton = forwardRef<HTMLElement, DSDeckPickerButtonProps>(
  (props, passedRef) => {
    const {
      deck,
      deckId,
      getProjects,
      maxChars,
      badDeck,
      canModify,
      root,
      onPickDeckId,
      allowSetNone,
      category,
      noDeckLabel = "Not in a deck",
      noDeckIcon = <DSIconGhost />,
      renderOverlay,
      filterFn,
      ...rest
    } = props;
    const getLabel = () => {
      if (!deck) {
        return deckId ? "Inaccessible Deck" : noDeckLabel;
      }
      if (deck.$meta.isDeleted()) {
        if (process.env.REACT_APP_MODE === "open") {
          return "Some Deck";
        } else {
          return (
            <Box as="span" textDecoration="line-through">
              Deleted Deck
            </Box>
          );
        }
      } else {
        return (
          <UnMarkedown maxChars={maxChars} projectId={deck.project.id} title={deck.title}>
            {deck.title}
          </UnMarkedown>
        );
      }
    };

    const image = deck || deckId ? <DeckPickerImage deck={deck} /> : noDeckIcon;

    const {reactiveIcon, state, handleClick} = useReactiveIcon({
      iconEl: image,
      onClick: onPickDeckId,
    });

    const {
      ref: dropDownRef,
      toggle,
      overlayElement,
      isOpen,
    } = useNextDropDown({
      overlayProps: {
        placement: "bottom",
        distanceFromAnchor: 10,
        renderOverlay: ({close, ...overlayProps}: any) =>
          renderOverlay ? (
            renderOverlay({overlayProps, close})
          ) : (
            <ArrowOverlay arrowSize="sm" {...overlayProps}>
              <Col pa="12px" width="cardPropChangeOverlay">
                <DeckListPicker
                  root={root}
                  currentOptionKey={deck?.id ?? null}
                  onChange={async (next) => {
                    await handleClick(next.deckId);
                    close();
                  }}
                  getProjects={getProjects}
                  allowNone={allowSetNone}
                  filterFn={filterFn}
                />
              </Col>
            </ArrowOverlay>
          ),
      },
    });

    useGlobalKeyPress({
      key: "d",
      fn: toggle,
      description: "Change deck",
      category,
      disabled: canModify === false,
    });

    const ref = useMemo(() => mergeRefs([dropDownRef, passedRef]), [dropDownRef, passedRef]);

    return (
      <>
        {overlayElement}
        <TooltipForChild
          tooltip={canModify ? null : "You may not modify this Card's deck"}
          delayed
          ref={ref}
        >
          <DSRawButton
            variant="secondary"
            size="sm"
            active={isOpen || badDeck}
            state={state}
            contentPadding={null}
            className={css({zIndex: 2})}
            onClick={canModify ? toggle : undefined}
            {...rest}
          >
            <Row sp="4px" align="center">
              {reactiveIcon}
              <Box pl="2px">{getLabel()}</Box>
              {canModify && <DSIconChevronDown className={css({ml: "auto"})} />}
            </Row>
          </DSRawButton>
        </TooltipForChild>
      </>
    );
  }
);
