import {Link} from "react-router-dom";
import {getStatusForCard} from "../../lib/card-status-utils";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import routes from "../../routes";
import publicProjectRoutes from "../public-project/public-project-routes";
import {DSCardLinkStyles} from "@cdx/ds/components/DSCard/DSCardLink.css";
import {cx, TooltipForChild} from "@cdx/common";
import {getParentCard} from "../workflows/workflow-utils";
import CardComp from "../../components/Card";
import {useInstance, useRoot} from "../../lib/mate/mate-utils";
import {ComponentProps, forwardRef, ReactElement} from "react";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {cardUrlState} from "../../layouts/arena-layout/createArenaContext";
import {Card, CardId} from "../../cdx-models/Card";
import {ArenaCtx, CardUrlOpts} from "../card-panel/card-panel-utils";
import {Activity} from "../../cdx-models/Activity";
import {classifyFeedEntry} from "./feed-entry/classify-feed-entry";

export type FeedEntryComp<T = any> = (props: {
  values: T[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
  root: any;
}) => ReactElement;

export type GroupedActivityItem<T = any> = {
  key: string;
  values: T[];
  firstItem: Activity;
  isComment: boolean;
  Comp: FeedEntryComp;
};

export const groupActivityItems = (items: Activity[]): GroupedActivityItem[] => {
  let lastKey: string | null = null;
  let currVals: any[] = [];
  let groups: GroupedActivityItem[] = [];
  for (const item of items) {
    const {key, val, isComment, Comp} = classifyFeedEntry(item);
    if (key !== lastKey) {
      currVals = [val];
      groups.push({key: `${item.id}-${key}`, values: currVals, firstItem: item, isComment, Comp});
      lastKey = key;
    } else {
      currVals.push(val);
    }
  }
  return groups;
};

export const LazyCardAsText = ({cardId}: {cardId: CardId}) => {
  const card: Card = useInstance("card", cardId);
  return <CardAsText card={card} />;
};

type CardAsTextProps = {
  card: Card;
  className?: string;
  inline?: boolean;
  maxChars?: number;
  onClick?: (e: any) => void;
} & (
  | {
      as?: undefined;
    }
  | {
      as: typeof Link;
      to: ComponentProps<typeof Link>["to"];
    }
);

export const CardAsText = forwardRef<HTMLDivElement, CardAsTextProps>((props, ref) => {
  const {
    card,
    className,
    inline = true,
    as: Comp = inline ? "span" : "div",
    maxChars,
    ...rest
  } = props;
  const parentCard = getParentCard(card);
  const pTitle = parentCard && parentCard.$meta.get("title", null);
  const status = getStatusForCard(card);

  return (
    <Comp
      className={cx(
        cardStatusVariants[status],
        DSCardLinkStyles.container.base,
        inline ? DSCardLinkStyles.container.inline : DSCardLinkStyles.container.nonInline,
        className
      )}
      {...rest}
      ref={ref}
    >
      {pTitle && (
        <UnMarkedown
          as="span"
          maxChars={20}
          className={cx(
            DSCardLinkStyles.parentCard.base,
            inline && DSCardLinkStyles.parentCard.inline
          )}
          onDark
        >
          {pTitle}
        </UnMarkedown>
      )}
      <UnMarkedown
        projectId={card.deck && card.deck.project.id}
        as="span"
        maxChars={
          maxChars && (pTitle ? Math.max(10, maxChars - Math.min(20, pTitle.length)) : maxChars)
        }
      >
        {card.title}
      </UnMarkedown>
    </Comp>
  );
});

const CardOverlay = ({card}: {card: Card}) => {
  const root = useRoot();
  return <CardComp card={card} root={root} />;
};

export const getCardLink = (
  card: Card,
  arenaCtx?: ArenaCtx,
  opts: Omit<CardUrlOpts, "card"> = {}
) => {
  // TODO: check in which context arenaCtx is set but arenaCtx.routes is not (logs suggest this is the case)
  if (arenaCtx?.routes && !arenaCtx.routes.stateBased)
    return arenaCtx.routes.getCardUrl({card, ...opts});
  const getPathname = () => {
    if (process.env.REACT_APP_MODE === "open") {
      if (!card.deck) return null;
      return publicProjectRoutes.publicProjectWithDeckAndCard.getUrl(
        card.deck.project,
        card.deck,
        card
      );
    } else {
      if (card.deck) {
        return routes.deckWithCard.getUrl(card.deck, card);
      } else {
        return routes.handWithCard.getUrl(card);
      }
    }
  };
  const pathname = getPathname();
  if (!pathname) return null;
  const {targetPanel, resolvableId, cardContainerKey} = opts;
  return {
    pathname,
    state: cardUrlState({targetPanel, resolvableId, cardContainerKey}),
  };
};

type CardLinkProps = {
  arenaCtx?: ArenaCtx;
} & CardAsTextProps;
export const CardLink = ({card, arenaCtx, ...rest}: CardLinkProps) => {
  if (!card || card.$meta.isDeleted()) return null;
  const to = getCardLink(card, arenaCtx);
  return (
    <TooltipForChild tooltip={<CardOverlay card={card} />} bg="gray800" style={{padding: 15}}>
      {to ? (
        <CardAsText card={card} as={Link} to={to} {...rest} />
      ) : (
        <CardAsText card={card} {...rest} />
      )}
    </TooltipForChild>
  );
};
