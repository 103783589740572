import {forwardRef} from "react";
import LinkOrButton from "./LinkOrButton";
import ButtonBehaviour from "./ButtonBehaviour";
import {ghostButtonStyles, rawButtonStyle} from "./button.css";
import cx from "../cx";
import dsStyles from "@cdx/ds/css/index.css";

const GhostButton = forwardRef(
  ({color, size, square, active, className, noShrink, ...rest}, ref) => (
    <LinkOrButton
      ref={ref}
      className={cx(
        rawButtonStyle,
        ghostButtonStyles.default,
        ghostButtonStyles.shadow[size === "sm" ? "sm" : "other"][
          active ? "active" : color || "default"
        ],
        rest.disabled && ghostButtonStyles.disabled,
        active || color
          ? ghostButtonStyles.colors[active ? "active" : color]
          : ghostButtonStyles.colors.default,
        size ? ghostButtonStyles.sizes[size] : ghostButtonStyles.sizes.md,
        square && ghostButtonStyles.squarePadding[size || "md"],
        noShrink && dsStyles.flex.none,
        className
      )}
      {...rest}
    />
  )
);

/**
 * @type any;
 */
const XGhostButton = forwardRef((props, ref) => (
  <ButtonBehaviour comp={GhostButton} ref={ref} {...props} />
));

export default XGhostButton;
