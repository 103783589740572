export function union<T>(listOfSets: Set<T>[]) {
  return listOfSets.reduce((m, set) => {
    set.forEach((val) => m.add(val));
    return m;
  }, new Set());
}

export const equalSets = (a: Set<any>, b: Set<any>) => {
  if (a.size !== b.size) return false;
  for (const val of a) {
    if (!b.has(val)) return false;
  }
  return true;
};

export const listsWithSameElements = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  const aSet = new Set(a);
  for (const val of b) {
    if (!aSet.has(val)) return false;
  }
  return true;
};

export function intersection<T>(listOfSets: Set<T>[]) {
  if (listOfSets.length === 0) return new Set();
  return listOfSets.reduce((m, set) => {
    const m2 = new Set([...m]);
    m2.forEach((val) => {
      if (!set.has(val)) m2.delete(val);
    });
    return m2;
  });
}

export const chunkArray = <T>(arr: T[], chunkSize: number) => {
  const results: T[][] = [];
  let i = 0;
  while (i < arr.length) {
    results.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return results;
};

export const splitArray = <T>(arr: T[], parts: number) => {
  const chunkSize = Math.ceil(arr.length / parts);
  return chunkArray(arr, chunkSize);
};

export const partition = <T>(arr: T[], filterfn: (el: T) => boolean) => {
  return arr.reduce(
    (memo, el) => {
      memo[filterfn(el) ? 0 : 1].push(el);
      return memo;
    },
    [[], []] as [T[], T[]]
  );
};

export const groupByFn = <T, K extends string>(
  arr: T[],
  classifyFn: (t: T) => K
): Record<K, T[]> => {
  return arr.reduce(
    (memo, el) => {
      const key = classifyFn(el);
      (memo[key] = memo[key] || []).push(el);
      return memo;
    },
    {} as Record<K, T[]>
  );
};

export const groupByAsMap = <T, K>(arr: T[], classifyFn: (t: T) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>();
  for (const el of arr) {
    const key = classifyFn(el);
    (map.get(key) || []).push(el);
  }
  return map;
};

export const getCountByField = <T, K extends keyof T>(arr: T[], field: K) => {
  const counts = new Map<T[K], number>();
  for (const el of arr) {
    const key = el[field];
    counts.set(key, (counts.get(key) || 0) + 1);
  }
  return counts;
};

export const getCountObjectByField = <T, K extends keyof T>(arr: T[], field: K) => {
  const counts = new Map<T[K], {value: T; count: number}>();
  for (const el of arr) {
    const key = el[field];
    const exist = counts.get(key);
    if (exist) {
      exist.count += 1;
    } else {
      counts.set(key, {value: el, count: 1});
    }
  }
  return counts;
};

export const getCountObjectWithGetter = <T, K>(arr: T[], getter: (el: T) => K) => {
  const counts = new Map<K, {value: T; count: number}>();
  for (const el of arr) {
    const key = getter(el);
    const exist = counts.get(key);
    if (exist) {
      exist.count += 1;
    } else {
      counts.set(key, {value: el, count: 1});
    }
  }
  return counts;
};

export const getElementWithMaxValue = <T>(arr: T[], getValue: (el: T) => number): T | null => {
  let maxValue = Number.NEGATIVE_INFINITY;
  let maxElement = null;
  for (const el of arr) {
    const value = getValue(el);
    if (value > maxValue) {
      maxValue = value;
      maxElement = el;
    }
  }
  return maxElement;
};
