import {Col, DSIconBan, DSIconButton, DSIconCheck, Row, css} from "@cdx/ds";
import {convoStyles as styles} from "./convo.css";
import {ConvoLine} from "./Comment";
import {ConvoAction} from "./get-convo-actions";
import {
  ClickOutside,
  DefaultOverlay,
  OverlayPlacer,
  Portal,
  springConfigs,
  TooltipForChild,
  useReveal,
} from "@cdx/common";
import {ResolvableBag} from "./convo-utils";
import {api} from "../../../../lib/api";
import {Project} from "../../../../cdx-models/Project";
import Emoji from "../../../../components/Markdown/Emoji";
import {hoverGroupContainer} from "@cdx/ds/css/decoration.css";
import {ReactElement, useRef, useState} from "react";
import {Resolvable} from "../../../../cdx-models/Resolvable";

const createOpenManager = (onChange: (val: boolean) => void) => {
  let open = false;
  let switchTimeoutId: null | ReturnType<typeof setTimeout> = null;
  return {
    open: () => {
      if (open) {
        if (switchTimeoutId) {
          clearTimeout(switchTimeoutId);
          switchTimeoutId = null;
        }
        return;
      }
      if (switchTimeoutId) return;
      switchTimeoutId = setTimeout(() => {
        switchTimeoutId = null;
        onChange(true);
        open = true;
      }, 500);
    },
    close: ({now = false}: {now?: boolean} = {}) => {
      if (!open) {
        if (switchTimeoutId) {
          clearTimeout(switchTimeoutId);
          switchTimeoutId = null;
        }
        return;
      }
      if (now) {
        if (switchTimeoutId) {
          clearTimeout(switchTimeoutId);
          switchTimeoutId = null;
        }
        onChange(false);
        open = false;
      } else {
        if (switchTimeoutId) return;
        switchTimeoutId = setTimeout(() => {
          switchTimeoutId = null;
          onChange(false);
          open = false;
        }, 500);
      }
    },
  };
};

const useHoverOverlay = ({
  overlayPlacerProps,
  renderOverlay,
}: {
  overlayPlacerProps: any;
  renderOverlay: (overlayProps: any) => ReactElement;
}) => {
  const [node, setNode] = useState(null);
  const [open, setOpen] = useState(false);
  const managerRef = useRef(createOpenManager(setOpen));
  const reveal = useReveal(open, {config: springConfigs.quick} as any);

  const triggerOpen = () => {
    managerRef.current.open();
  };

  const cancelTrigger = ({now = false}: {now?: boolean} = {}) => {
    managerRef.current.close({now});
  };

  const overlayElement = reveal((props: any) => (
    <Portal>
      <OverlayPlacer
        node={node}
        presenceProps={props}
        isOpen
        {...overlayPlacerProps}
        renderOverlay={(overlayProps: any) => (
          <ClickOutside onClickOutside={() => cancelTrigger({now: true})}>
            {(handlers: any) =>
              renderOverlay({
                ...overlayProps,
                ...handlers,
                onMouseEnter: triggerOpen,
                onMouseLeave: cancelTrigger,
                onClose: () => cancelTrigger({now: true}),
              })
            }
          </ClickOutside>
        )}
      />
    </Portal>
  ));

  return {ref: setNode, triggerOpen, cancelTrigger, overlayElement};
};

export const ApproveReaction = ({value, inline}: {value: string; inline?: boolean}) => {
  switch (value) {
    case ":check:":
      return <DSIconCheck inline={inline} size={inline ? 16 : null} />;
    case ":optOut:":
      return <DSIconBan inline={inline} size={inline ? 16 : null} />;
    default:
      return (
        <Emoji className={css({size: 22})} block={!inline}>
          {value}
        </Emoji>
      );
  }
};

const Overlay = ({
  overlayProps,
  reactions,
  onClickReaction,
}: {
  overlayProps: any;
  reactions: string[];
  onClickReaction: (reaction: string) => unknown;
}) => {
  return (
    <DefaultOverlay
      {...overlayProps}
      noBg
      className={css({colorTheme: "gray750", bg: "foreground", rounded: 4})}
    >
      <Row sp="8px" pa="16px">
        {reactions.map((r) => (
          <DSIconButton
            key={r}
            size="lg"
            variant="secondary"
            onClick={() => onClickReaction(r)}
            icon={
              <div style={{width: 25}}>
                <ApproveReaction value={r} />
              </div>
            }
          />
        ))}
      </Row>
    </DefaultOverlay>
  );
};

const getCtxInfo = (ctx: Resolvable["context"]) => {
  if (ctx === "review")
    return {
      reactions: [":check:", "👍", "💖", "🤯", "🤷", "🙏"],
      status: "approve",
      buttonProps: {theme: "success"} as const,
    };
  return {
    reactions: [":optOut:", "👍", "💖", "🤯", "🤷", "🙏"],
    status: "opt_out",
    buttonProps: {
      variant: "secondary",
    } as const,
  };
};

type ActionButtonProps = {
  action: ConvoAction;
  isWriting?: boolean;
  isLast?: boolean;
  project: Project | null;
  resBag: ResolvableBag;
};

const ApproveButton = (props: ActionButtonProps) => {
  const {isWriting, isLast, action, resBag} = props;
  const resolvableId = resBag.resolvable.$meta.get("id", null);
  const meId = resBag.root.loggedInUser.$meta.get("id", null);
  const disabled = isWriting || action.disabled;

  const {reactions, status, buttonProps} = getCtxInfo(resBag.resolvable.context);

  const approveWithReaction = (reaction: string) => {
    return api.mutate.resolvables.updateParticipantDone({
      resolvableId,
      userId: meId,
      done: true,
      status,
      reaction,
    });
  };

  const firstReaction = reactions[0];

  const {ref, triggerOpen, cancelTrigger, overlayElement} = useHoverOverlay({
    overlayPlacerProps: {placement: "top", distanceFromAnchor: 10},
    renderOverlay: ({onClose, ...overlayProps}: any) => (
      <Overlay
        reactions={reactions}
        onClickReaction={async (r) => {
          await approveWithReaction(r);
          onClose();
        }}
        overlayProps={overlayProps}
      />
    ),
  });

  return (
    <>
      <TooltipForChild
        tooltip={isWriting ? "Post your comment above before closing." : null}
        targetIsDisabled={disabled}
        placement="left"
        ref={ref as any}
        onMouseEnter={triggerOpen}
        onMouseLeave={cancelTrigger}
      >
        <Row className={hoverGroupContainer} alignSelf="start">
          <DSIconButton
            size="sm"
            onClick={() => approveWithReaction(firstReaction)}
            disabled={disabled}
            icon={<ApproveReaction value={firstReaction} />}
            {...buttonProps}
          />
          <div
            className={css({
              textType: "label14",
              color: "secondary",
              cursor: "pointer",
              pt: "4px",
              pl: "12px",
            })}
            onClick={(disabled ? null : () => approveWithReaction(firstReaction)) ?? undefined}
          >
            {action.message}
          </div>
        </Row>
      </TooltipForChild>
      {!isLast && (
        <Col height="24px" width="24px" className={styles.setAccentAsBorder}>
          <ConvoLine />
        </Col>
      )}
      {overlayElement}
    </>
  );
};

export default ApproveButton;
