import {TooltipForChild, formatMsAsMin} from "@cdx/common";
import {ReactElement, ReactNode, forwardRef} from "react";
import {
  Box,
  Col,
  DSIconBeast,
  DSIconBell,
  DSIconClock,
  DSIconPyramid,
  NonInteractiveDSTag,
  Row,
  Text,
  css,
} from "@cdx/ds";
import {CdxImgByFile} from "../../components/CdxImg";
import {setMsThemeColor} from "../../features/milestones/milestone-utils";
import {Sprint, SprintId} from "../../cdx-models/Sprint";
import {checkIfPresent, useInstance, useRoot} from "../../lib/mate/mate-utils";
import {api} from "../../lib/api";
import {
  ThemedSprintIcon,
  getSprintState,
  getSprintVelocity,
  sprintLabel,
} from "../../features/milestones/sprint-utils";
import {MarkdownToPreview} from "../../components/Markdown/markdownToPreview";
import {
  dayToDate,
  prettyWorkdaysForAccount,
  shortDate,
  startOfDay,
  superShortDateWithWeekdayIfThisYear,
} from "../../lib/date-utils";
import {StatGroups, getLastProgress} from "./MilestonesOverview/MilestoneOverviewComponents";
import {milestoneIconStyle} from "../../features/milestones/milestones.css";
import {SprintStats} from "../../cdx-models/utils/extended-fields";
import {Root} from "../../cdx-models/Root";

export const TimeContainer = ({label, date, root}: {label: string; date: Date; root: Root}) => (
  <Row sp="8px" align="baseline">
    <Text type="label11Caps" color="primary" minWidth="32px">
      {label}
    </Text>
    <Text type="label12light" color="primary">
      {superShortDateWithWeekdayIfThisYear(date)}
    </Text>
    <Text type="label12light" color="secondary" ml="auto">
      {prettyWorkdaysForAccount({account: root.account, targetDay: date})}
    </Text>
  </Row>
);

const SprintStatePill = ({sprint, root}: {sprint: Sprint; root: Root}) => {
  const state = getSprintState(sprint);
  const hasBeastMode = Boolean(sprint.$meta.get("lockedAt", null));
  const getLabel = () => {
    switch (state) {
      case "next":
        return "Upcoming Run";
      case "current":
        return "Current Run";
      case "past":
        return "Past Run";
      default:
        return "Run";
    }
  };
  return (
    <NonInteractiveDSTag theme="dim" className={css({ml: "auto"})}>
      <Row sp="4px" align="center">
        {hasBeastMode && <DSIconBeast size={16} className={css({color: "secondary"})} />}
        {getLabel()}
      </Row>
    </NonInteractiveDSTag>
  );
};

const SprintOverviewTooltipContent = ({
  sprint,
  note,
  zoneInfo,
}: {
  sprint: Sprint;
  note?: ReactNode | false;
  zoneInfo?: string | null;
}) => {
  const root = useRoot();
  const trackedTimeSecs = sprint.$meta.get("stats", {trackedTimeSecs: 0}).trackedTimeSecs ?? 0;
  const {isLoaded, result} = checkIfPresent(() => sprint.coverFile, api);
  const coverFile = isLoaded ? result : null;
  const startDate = dayToDate(sprint.startDate);
  const endDate = dayToDate(sprint.endDate);
  const finishStats: SprintStats["finishStats"] = (sprint.stats as any).finishStats;
  const manualEndDate = finishStats?.date && new Date(finishStats.date);
  const differentManualEndDate =
    manualEndDate &&
    endDate &&
    startOfDay(manualEndDate).getTime() !== startOfDay(endDate).getTime();
  return (
    <Col style={{width: 360}}>
      {coverFile && (
        <CdxImgByFile
          file={coverFile}
          maxWidth={600}
          imgClassName={css({width: "100%", maxHeight: "64px"})}
          loadingClassName={css({maxHeight: "64px"})}
        />
      )}
      <Col className={setMsThemeColor(sprint.sprintConfig)} divideX minWidth="0">
        <Row sp="8px" align="start" px="16px" py="12px" relative minWidth="0">
          <ThemedSprintIcon
            size={16}
            theme={setMsThemeColor(sprint.sprintConfig)}
            sprintState={getSprintState(sprint)}
            className={css({position: "relative", top: "4px"}, milestoneIconStyle.asDsIcon.onLight)}
          />
          <Col flex="auto" minWidth="0">
            <Row sp="8px" size={16} align="center">
              <Row align="baseline" sp="8px">
                <Box color="secondary">{sprint.sprintConfig.name}</Box>
                <Box color="primary" bold>
                  {sprintLabel(sprint)}
                </Box>
              </Row>
              <SprintStatePill sprint={sprint} root={root} />
            </Row>
            {sprint.description && (
              <Text type="prose14" color="primary">
                <MarkdownToPreview content={sprint.description} maxLength={32} />
              </Text>
            )}
          </Col>
        </Row>
        <Col px="16px" py="12px" sp="4px">
          <Row sp="8px" align="center">
            <DSIconBell size={16} className={css({color: "secondary"})} />
            <Col sp="8px" flex="auto">
              <TimeContainer label="Start" date={startDate} root={root} />
              <TimeContainer label="End" date={endDate} root={root} />
            </Col>
          </Row>
          {differentManualEndDate && (
            <Row sp="8px" align="center" style={{paddingLeft: 16 + 8 + 32 + 4}}>
              <Box color="secondary" size={12}>
                Manually ended on {shortDate(manualEndDate)}
              </Box>
            </Row>
          )}
          {trackedTimeSecs > 0 && (
            <Row sp="8px" align="center" pt="8px">
              <DSIconClock size={16} className={css({color: "secondary"})} />
              <Box color="primary" size={12}>
                <b>{formatMsAsMin(trackedTimeSecs * 1000)}</b> tracked
              </Box>
            </Row>
          )}
        </Col>

        <StatGroups
          account={root.account}
          stats={finishStats ? finishStats : (sprint.stats as any)}
          velocity={getSprintVelocity(sprint, root.account)}
          lastProgress={getLastProgress(sprint as any) as any}
          className={css({px: "16px", py: "24px"})}
        />
        {zoneInfo && (
          <Row sp="8px" align="center" px="16px" py="12px">
            <DSIconPyramid size={16} className={css({color: "secondary"})} />
            <Text type="label14" color="primary">
              {zoneInfo}
            </Text>
          </Row>
        )}
      </Col>
      {note && (
        <Box
          size={14}
          colorTheme="gray50"
          color="secondary"
          bg="foreground"
          px="16px"
          py="12px"
          lineHeight="none"
        >
          {note}
        </Box>
      )}
    </Col>
  );
};

type SprintTooltipProps = {
  sprint: Sprint;
  tooltip?: string | false;
  placement?: "right" | "left" | "top" | "bottom";
  children: ReactElement;
  zoneInfo?: string | null;
};

export const SprintTooltipForChild = forwardRef<HTMLElement, SprintTooltipProps>(
  ({sprint, tooltip, placement = "right", zoneInfo, ...rest}, ref) => (
    <TooltipForChild
      tooltip={<SprintOverviewTooltipContent sprint={sprint} note={tooltip} zoneInfo={zoneInfo} />}
      bg="white"
      placement={placement}
      ref={ref}
      {...rest}
    />
  )
);

type SprintByIdTooltipProps = {
  sprintId: SprintId;
  tooltip?: ReactNode | false;
  placement?: "right" | "left" | "top" | "bottom";
  children: ReactElement;
  zoneInfo?: string | null;
};

type SprintByIdOverviewTooltipContentProps = {
  sprintId: SprintId;
  note?: ReactNode | false;
  zoneInfo?: string | null;
};
const SprintByIdOverviewTooltipContent = ({
  note,
  sprintId,
  zoneInfo,
}: SprintByIdOverviewTooltipContentProps) => {
  const sprint = useInstance("sprint", sprintId);
  return sprint && <SprintOverviewTooltipContent sprint={sprint} note={note} zoneInfo={zoneInfo} />;
};

export const SprintByIdTooltipForChild = forwardRef<HTMLElement, SprintByIdTooltipProps>(
  ({sprintId, tooltip, placement = "right", zoneInfo, ...rest}, ref) => (
    <TooltipForChild
      tooltip={
        <SprintByIdOverviewTooltipContent sprintId={sprintId} note={tooltip} zoneInfo={zoneInfo} />
      }
      bg="white"
      placement={placement}
      ref={ref}
      {...rest}
    />
  )
);
