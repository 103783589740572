import {useCallback} from "react";
import {History, Location} from "history";
import {getStatusForCard} from "../../lib/card-status-utils";
import {waitForResultPromise} from "../../lib/wait-for-result";
import useMutation from "../../lib/hooks/useMutation";
import {api} from "../../lib/api";
import {Card, CardId} from "../../cdx-models/Card";
import {Account} from "../../cdx-models/Account";
import {Deck} from "../../cdx-models/Deck";
import {Root} from "../../cdx-models/Root";
import {WorkflowItem, WorkflowItemId} from "../../cdx-models/WorkflowItem";
import {toCardPath} from "../../layouts/arena-layout/createArenaContext";

export const getShownEffort = (card: Card, account: Account): number | null => {
  if (
    card.childCards.length === 0 ||
    (card.childCards.length === 1 && !card.childCards[0].$meta.isLoaded)
  ) {
    return card.effort;
  } else {
    const childEffort = calcChildCardEffort(getChildCards(card), account);

    return childEffort + (card.effort ?? 0);
  }
};

const calcChildCardEffort = (childCards: Card[], account: Account): number => {
  let sum = 0;
  childCards.forEach((c) => {
    if ((c.status !== "done" && c.visibility !== "default") || c.isDoc) return;
    const eff = c.$meta.get("effort", null);
    sum += eff ?? account.fallbackEffort;
  });
  return sum;
};

export const getChildCards = (card: Card): Card[] => {
  const {childCards} = card;
  if (!childCards || (childCards.length === 1 && !childCards[0].$meta.isLoaded)) return [];
  return process.env.REACT_APP_MODE === "open"
    ? childCards.filter((c: any) => c.isPublic)
    : childCards;
};

export const hasChildCards = (card: Card): boolean => {
  const childCards = getChildCards(card);
  if (childCards.length === 0) return false;
  return childCards[0].$meta.isLoaded;
};

export const areChildCardsIncomplete = (childCards: Card[]): boolean => {
  const considerableChildren = childCards.filter(
    (c) => (c.status === "done" || c.visibility !== "archived") && !c.isDoc
  );
  if (considerableChildren.length === 0) return false;
  const allDoablesDone = considerableChildren.every(
    (c) => c.status === "done" || c.visibility === "archived"
  );
  if (!allDoablesDone) return true;
  return false;
};

export const isHeroCard = (card: Card): boolean => {
  const childCards = getChildCards(card);
  if (childCards.length === 0) {
    // return false;
    if (!card.deck) return false;
    const {allowedCardTypes} = card.deck;
    if (!allowedCardTypes.includes("task") && allowedCardTypes.includes("hero")) {
      if (card.isDoc) return false;
      return true;
    }
    return false;
  }
  return childCards[0].$meta.isLoaded;
};

export const getParentCard = (card: Card): Card | null => {
  if (card.parentCard === null || card.parentCard.$meta.isDeleted()) return null;
  if (process.env.REACT_APP_MODE === "open") {
    return card.parentCard.$meta.get("isPublic", false) ? card.parentCard : null;
  }
  return card.parentCard;
};

export const getChildCardInfo = (card: Card, account: Account) => {
  const childCards = getChildCards(card);
  if (childCards.length === 0) return null;

  const childEffort = calcChildCardEffort(childCards, account);
  let count = 0;

  const info: Record<"started" | "blocked" | "review" | "done" | "rest", number[]> & {
    effort: number;
    count: number;
  } = {
    started: [],
    blocked: [],
    review: [],
    done: [],
    rest: [],
    effort: childEffort + (card.effort || 0),
    count,
  };

  childCards.forEach((c) => {
    const status = getStatusForCard(c) as "started" | "doc";
    if (status in info) {
      info[status as "started"].push(c.effort ?? account.fallbackEffort);
    } else if (card.isDoc && status === "doc") {
      // if parent card is a doc hero card, each doc card counts as a "done" entry
      info.done.push(c.effort ?? account.fallbackEffort);
    } else if ((c.visibility === "default" || c.status === "done") && status !== "doc") {
      info.rest.push(c.effort ?? account.fallbackEffort);
    }
    info.count += 1;
  });

  return info;
};

export const areValidChildren = (cards: Card[]) => {
  if (cards.some((c) => !c.deck)) {
    return {isValid: false, msg: "Can't add private cards as sub cards."};
  } else if (cards.some((c) => c.childCards.length > 0)) {
    return {isValid: false, msg: "Can't add Hero cards as sub cards."};
  } else if (cards.some((c) => c.parentCard && c.parentCard.$meta.isDeleted())) {
    return {isValid: false, msg: "Card is already part of inaccessible Hero card."};
  }
  return {isValid: true};
};

export const checkDropCards = (
  parentCard: Card,
  cardInfos: {cardId: string; getCard: () => Card}[],
  {mayDropExistingChildren}: {mayDropExistingChildren?: boolean} = {}
) => {
  if (!parentCard || parentCard.$meta.isDeleted()) {
    return {canDrop: false, msg: "You don't have access to the parent card"};
  }
  if (!parentCard.deck) return {canDrop: false, msg: "Can't turn private card into a Hero card."};
  if (parentCard.isDoc && !hasChildCards(parentCard)) {
    return {canDrop: false, msg: "Can't turn Doc cards into Hero cards."};
  }
  const allCardsAreMyChildren = cardInfos.every((i) =>
    parentCard.childCards.find((c) => c.cardId === i.cardId)
  );
  if (!mayDropExistingChildren && allCardsAreMyChildren) {
    return {
      canDrop: false,
      msg: "Can't drop: already sub card of this card.",
      isAlreadyChild: true,
    };
  } else if (cardInfos.some((i) => i.cardId === parentCard.cardId)) {
    return {canDrop: false, msg: "Can't drop a card onto itself."};
  } else {
    const cards = cardInfos.map((i) => i.getCard());
    const {isValid, msg} = areValidChildren(cards);
    return {canDrop: isValid, msg};
  }
};

export const useAddCardToParent = () => {
  const [update] = useMutation("cards", "update");
  return useCallback(
    (opts: {parentCardId: CardId; childCardIds: CardId[]}) =>
      waitForResultPromise(() => {
        const parentCard: Card = api.getModel({modelName: "card", id: opts.parentCardId});
        return {
          existingIds:
            (parentCard.$meta.get("childCards", [], {forceRelIds: true}) as any as CardId[]) || [],
        };
      }).then(({existingIds}) => {
        const set = new Set(existingIds);
        return update({
          id: opts.parentCardId,
          childCards: [...existingIds, ...opts.childCardIds.filter((id) => !set.has(id))],
        });
      }),
    [update]
  );
};

export const deckHasDefaultCard = (deck: Deck) => {
  if (!deck.defaultCard) return false;
  const {
    effort = null,
    priority = null,
    assigneeId = null,
    masterTags = [],
    content,
  } = deck.defaultCard as Record<string, any>;
  return (
    effort !== null ||
    priority !== null ||
    assigneeId !== null ||
    (masterTags || []).length > 0 ||
    content
  );
};

export type ChildCardInfo = {
  groups: {
    id: number;
    label: string | null;
    description: string | null;
    cards: string[];
  }[];
};

type Entry = {id: CardId; card: Card | null};
export type ChildCardEntry = Entry;

export type ChildCardInfoGroup = {
  id: number;
  label: string | null;
  description: string | null;
  entries: Entry[];
  isDefaultGroup: boolean;
};

export const getChildCardEntries = (card: Card, opts: {includeInaccessible: boolean}) => {
  const showAll = process.env.REACT_APP_MODE !== "open" && opts.includeInaccessible;
  const childCardEntries: {id: CardId; card: Card | null}[] = showAll
    ? card.childCards.map((c) => ({id: c.cardId as CardId, card: c}))
    : (card.$meta.get("childCards", [], {forceRelIds: true}) || []).map((id: any) => ({
        id: id as CardId,
        card: api.getModel({id, modelName: "card"}) as Card,
      }));
  return process.env.REACT_APP_MODE === "open"
    ? childCardEntries.filter((c) => c.card?.isPublic)
    : childCardEntries;
};

export const getItemsFromDeck = (deck: Deck) =>
  deck.$meta.find("workflowItems", {visibility: "default", $order: "sortValue"});

export type WorkflowPanelBag = {
  root: Root;
  history: History;
  location: Location;
  selectedIds: Set<WorkflowItemId>;
  addSelectedItemId: (id: WorkflowItemId) => void;
  removeSelectedItemId: (id: WorkflowItemId) => void;
  setSelection: (selection: Set<WorkflowItemId>) => void;
  canModify: boolean | null;
  deck: Deck;
  activeItem: WorkflowItem | null;
};

export const getWorkflowItemsWithZones = (deck: Deck) => {
  const items = getItemsFromDeck(deck);
  const zones: (string | null)[] = deck.workflowItemOrderLabels;
  if (zones.length === 0) zones.push(null);
  const map = new Map<string | null, WorkflowItem[]>(zones.map((label) => [label, []]));
  const defaultZone = map.get(zones[0])!;
  for (const item of items) {
    const zone = map.get(item.label) || defaultZone;
    zone.push(item);
  }
  return {zoneMap: map, items};
};

export const workflowRoutes = {
  root: {
    path: "/:deckId",
    getUrl: (deck: Pick<Deck, "accountSeq">) => `/${deck.accountSeq}`,
  },
  createItem: {
    path: "/:deckId/step/create",
    getUrl: (deck: Pick<Deck, "accountSeq">) => `/${deck.accountSeq}/step/create`,
  },
  defaultCard: {
    path: "/:deckId/template-card",
    getUrl: (deck: Pick<Deck, "accountSeq">) => `/${deck.accountSeq}/template-card`,
  },
  item: {
    path: "/:deckId/step/:itemId",
    getUrl: ({
      deck,
      item,
    }: {
      deck: Pick<Deck, "accountSeq">;
      item: Pick<WorkflowItem, "accountSeq" | "title">;
    }) => `/${deck.accountSeq}/step/${toCardPath(item)}`,
  },
};
