import {useState, useEffect} from "react";
import PushUpdates from "./PushUpdates"; // consider moving this and others into e.g. Root/PushUpdates
import ShortcutLister from "./ShortcutLister";
import messenger, {MessengerArea} from "../lib/messenger";
import {exceptionEvent} from "../lib/error-logger";
import ModalRegistry from "./ModalRegistry";
import {ConfirmationRegistry} from "../lib/confirm";
import {SetupHints} from "../features/hints/HintManager";
import "@cdx/common/fonts/fonts.css";
import "@fontsource-variable/roboto-mono";
import "modern-normalize/modern-normalize.css";
import "../global-styles.css";
import "overlayscrollbars/overlayscrollbars.css";
import TimerWidget from "../features/time-tracking/TimerWidget";
import {BlingProvider} from "../lib/hooks/useBling";
import {ClickOutsideProvider, KeyPressContextProvider, cx, errorToString} from "@cdx/common";
import UiHandlerContext from "@cdx/ds/components/DSForm/UiHandlerCtx";
import {ApiErrors} from "./ErrorOverlays";
import {useLocation, useHistory} from "react-router-dom";
import {useRoot} from "../lib/mate/mate-utils";
import {useSetTitle} from "./Title";
import {DeploymentInfo} from "./DeploymentInfo";
import {API_ERRORS} from "../lib/request";
import ReleaseAlerter from "../features/releases/ReleaseAlerter";
import useSyncTimezone from "../lib/hooks/useSyncTimezone";
import {apiErrorEvent} from "../lib/api-error-event";
import {rootStatusColorOverwrites} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import dsStyles from "@cdx/ds/css/index.css";
import cdxEnv from "../env";
import {Box, Col, DSIconButton, DSIconClose, Text} from "@cdx/ds";
import Emoji from "./Markdown/Emoji";

const uiHandlers = {
  onButtonError: (error) => {
    console.error("button click resulted in ", error);
    messenger.send(errorToString(error), {type: "error", msToRemove: 10000});
  },
};

const ErrorPill = ({onClose}) => (
  <Col
    position="fixed"
    zIndex={3}
    align="center"
    bottom="0"
    left="0"
    right="0"
    pb="12px"
    pointerEvents="none"
  >
    <Col
      rounded={4}
      pa="24px"
      colorTheme="alert800"
      elevation={300}
      bg="foreground"
      maxWidth="480px"
      width="100%"
      pointerEvents="auto"
      relative
    >
      <Box absolute style={{top: 15, right: 15}}>
        <DSIconButton icon={<DSIconClose />} variant="tertiary" negatePadding onClick={onClose} />
      </Box>
      <Text type="prose16" color="primary">
        Something just broke <Emoji>😣</Emoji>
        <br />
        You might need to reload this page.
      </Text>
    </Col>
  </Col>
);

const Root = ({children, notFound = "404", modals}) => {
  const root = useRoot();
  const location = useLocation();
  const history = useHistory();
  const [got404, set404] = useState();
  const [showExceptionThrown, setShowExceptionThrown] = useState(false);
  useSyncTimezone(root);

  useEffect(() => {
    return exceptionEvent.addListener(() => setShowExceptionThrown(true));
  }, []);

  useEffect(() =>
    apiErrorEvent.addListener((e) => {
      if (e && e.type === API_ERRORS.API_ERROR && e.status === 404) {
        set404(true);
      }
    })
  );

  useSetTitle(
    process.env.REACT_APP_MODE !== "open" && root.account && root.account.$meta.get("name", null),
    {pos: 3}
  );

  const statusColorPalette = root.loggedInUser?.$meta.get("statusColorPalette", "default");
  const paletteClassName =
    statusColorPalette !== "default" ? rootStatusColorOverwrites[statusColorPalette] ?? null : null;

  useEffect(() => {
    if (paletteClassName) {
      document.body.classList.add(paletteClassName);
    }
    return () => document.body.classList.remove(paletteClassName);
  }, [paletteClassName]);

  if (got404 && (!root.account || !root.account.$meta.isLoaded)) {
    return notFound;
  }

  if (!root.account) {
    return (
      <Col pa="24px" sp="8px">
        <Text type="title24">Organisation not found.</Text>
        <Text type="prose16">
          If this problem persists please get in touch with hello@codecks.io and tell us what
          happened to get here!
        </Text>
      </Col>
    );
  }

  return (
    <UiHandlerContext.Provider value={uiHandlers}>
      <KeyPressContextProvider>
        <ClickOutsideProvider>
          {(handlers) => (
            <div
              className={cx(
                dsStyles.position.relative,
                dsStyles.zIndex[0],
                dsStyles.minHeight["100%"]
              )}
              {...handlers}
            >
              {children}
              <ModalRegistry location={location} history={history} root={root} modals={modals} />
              <PushUpdates root={root} />
              <ShortcutLister />
              <MessengerArea />
              <ConfirmationRegistry />
              <DeploymentInfo />
              {cdxEnv.ON_PREMISE !== "true" && (
                <ReleaseAlerter root={root} location={location} history={history} />
              )}
              {process.env.REACT_APP_MODE !== "open" && <TimerWidget root={root} />}
              <BlingProvider />
              <ApiErrors history={history} location={location} />
              {showExceptionThrown && <ErrorPill onClose={() => setShowExceptionThrown(false)} />}
              <SetupHints root={root} />
            </div>
          )}
        </ClickOutsideProvider>
      </KeyPressContextProvider>
    </UiHandlerContext.Provider>
  );
};

export default Root;
