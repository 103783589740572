export const searchAndFilter = <T>(
  list: T[],
  toSearchString: (item: T) => string,
  searchTerm: string | null
): T[] => {
  if (!searchTerm) return list;
  const searchTermLower = searchTerm.toLowerCase();
  const sameCasePrefixBucket: T[] = [];
  const prefixBucket: T[] = [];
  const matchBucket: T[] = [];
  for (const el of list) {
    const term = toSearchString(el);
    if (term.startsWith(searchTerm)) {
      sameCasePrefixBucket.push(el);
    } else {
      const lc = term.toLowerCase();
      if (lc.startsWith(searchTermLower)) {
        prefixBucket.push(el);
      } else if (lc.includes(searchTermLower)) {
        matchBucket.push(el);
      }
    }
  }
  return [...sameCasePrefixBucket, ...prefixBucket, ...matchBucket];
};
